<template>
  <resto v-if="isResto"></resto>
  <cockpit-v2 v-else></cockpit-v2>
</template>

<script>
import CockpitV2 from "./components/CockpitV2.vue";
import Resto from "./components/Resto";
import store from "./store/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "Start",
  components: {
    CockpitV2,
    Resto,
  },
  setup() {
    let uri = window.location.href;
    let queryString = window.location.search.substring(1);
    let params = new URLSearchParams(queryString);
    const isResto = uri.indexOf("resto") > -1;

    store.dispatch("setUrlParams", params).then(() => {
      console.log('setUrlParams',params);
      if (!isResto) {
        //check if sale completed
        axios
          .get("/api/isExternalSaleCompleted", {
            params: {
              saleId: params.get("saleID"),
              shopKey: params.get("shopKey")
            },
          })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                title: "Sale already completed",
                text: "Sale is already complete, please return to Lightspeed! You can view the invoice at the MDware dashboard.",
                icon: "error",
                allowOutsideClick: false,
                confirmButtonColor: "#2c3b4f",
                confirmButtonText: "Return to Lightspeed",
              }).then((result) => {
                if (result.value) {
                  return window.location.replace(store.getters.returnUrl);
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    return {
      isResto,
    };
  },
  computed: {
    ...mapGetters(["customer", "shop", "shopKey", "user"]),
  },
  watch: {
    user() {
      this.addIntercom();
    },
    customer() {
      this.addIntercom();
    },
    shop() {
      this.addIntercom();
    },
    shopKey() {
      this.addIntercom();
    },
  },
  methods: {
    addIntercom() {
      let scriptCode = document.getElementById("IntercomScriptCode");
      if (scriptCode) {
        scriptCode.remove();
      }
      if (this.customer.email && this.shop.shopID && this.shopKey !== "") {
        console.log("Intercom Inititated");

        let scriptWithCode = document.createElement("script");
        scriptWithCode.setAttribute("id", "IntercomScriptCode");
        scriptWithCode.setAttribute("type", "text/javascript");

        let code = `window.Intercom('boot', {
          app_id: APP_ID,
          name: '${this.user ? this.user.name : this.shop.name}',
          email: '${this.user ? this.user.email : this.customer.email}', 
          user_id_v1: '${this.shopKey}',
        });

        window.smartlook || (function (d) {
            var o = smartlook = function () {
                o.api.push(arguments)
            }, h = d.getElementsByTagName('head')[0];
            var c = d.createElement('script');
            o.api = [];
            c.async = true;
            c.type = 'text/javascript';
            c.charset = 'utf-8';
            c.src = 'https://rec.smartlook.com/recorder.js';
            h.appendChild(c);
        })(document);
        smartlook('init', 'cda632facc65d30b27a26cea888f38f075073b97');
        smartlook('consentForms', 'I agree to share my user profile details to Smartlook via the privacy policy.');
        smartlook('consentIP', 'I agree to share my IP Address to Smartlook via the privacy policy.');
        smartlook('consentAPI', 'I agree to share my Intercom user profile details to Smartlook via the privacy policy.');

        smartlook('identify', '${this.shopKey}', {
            name: '${this.user ? this.user.name : this.shop.name}',
            email: '${this.user ? this.user.email : this.customer.email}', 
        });`;

        try {
          scriptWithCode.appendChild(document.createTextNode(code));
          document.body.appendChild(scriptWithCode);
        } catch (e) {
          scriptWithCode.text = code;
          document.body.appendChild(scriptWithCode);
        }
      }
    },
  },
  mounted() {
    this.addIntercom();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: gray;
}
</style>
