import { createI18n } from "vue-i18n";
import messages from "./messages";

const i18n = new createI18n({
  locale: "en-GB",
  fallback: "en-GB",
  messages,
});

export default i18n;
