<template>
  <div>
    <div class="my-2 w-11/12 mx-auto">
      <div class="my-2 float-left w-full">
        <label for="quotingNote" class="float-left py-1 pl-1"
          >Add note to quote for customer</label
        >
        <input
          class="px-2 py-1 border-2 h-10 rounded w-full"
          id="quotingNote"
          type="text"
          v-model="quotingNote"
          placeholder="Note"
        />
      </div>
    </div>
    <QuotingItemsModalContainer />
  </div>
</template>

<script>
// import store from "@/store/store";
import { ref, provide } from "vue";
import QuotingItemsModalContainer from "./QuotingItemsModal.vue";

export default {
  name: "Quoting",
  components: { QuotingItemsModalContainer },
  setup() {
    let quotingNote = ref("");
    provide("quotingNote", quotingNote);
    return {
      quotingNote,
    };
  },
};
</script>

<style scoped></style>
