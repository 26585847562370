export default {
  customer: {
    loading: true,
    errorMessage: "",
  },
  ccvPayUrl: "",
  applicationSettings: {
    loading: true,
  },
  sale: {
    calcTotal: 0.0,
    displayableTotal: 0.0,
  },
  roundedAmount: "",
  shopKey: "",
  type: "",
  accountID: "",
  systemUserID: "",
  employeeID: "",
  saleID: "",
  customerID: "",
  shopID: "",
  paramsInitialized: false,
  registerID: "",
  returnUrl: "#",
  saleData: {
    loading: true,
  },
  urlParams: [],
  hasWallee: false,
  showFinalizeButtons: true,
  saleLines: [],
  paymentMethods: [],
  terminalPayments: [],
  balance: 0,
  loyaltyEvents: [],
  shop: {
    loading: true,
  },
  giftCardData: [],
  note: "",
  change: 0,
  ccvPayment: {
    amount: 0,
    errorMessage: "",
    status: "",
    showModal: false,
  },
  sendMail: false,
  email: "",
  email2: "",
  canFinalize: true,
  isCreditNota: false,
  user: null,
  hasSpecialItems: false,
  loyaltyAdded: false,
  loyaltyUsed: false,
  isLoyaltyRedeemLoading: false,
  isLoyaltyAddingLoading: false,
};
