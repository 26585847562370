import axios from "axios";
import store from "./store";
import Swal from "sweetalert2";

export default {
  setUrlParams({ commit }, payload) {
    let params = {};
    payload.forEach((value, key) => {
      params[key] = value;
    });
    commit("setUrlParams", params);

    commit("setResto", payload.has("resto") === true);
    commit("setShopKey", payload.get("shopKey"));
    commit("setSaleID", payload.get("saleID"));
    commit("setType", payload.get("type"));
    commit("setAccountID", payload.get("accountID"));
    commit("setSystemUserID", payload.get("systemUserID"));
    commit("setEmployeeID", payload.get("employeeID"));
    commit("setCustomerID", payload.get("customerID"));
    commit("setShopID", payload.get("shopID"));
    commit("setRegisterID", payload.get("registerID"));
    commit("setReturnUrl", payload.get("returnURL"));
    commit("setParamsInitialized", true);
  },
  setSale({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/externalSales", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
          accountId: store.getters.accountID,
          employeeId: store.getters.employeeID,
          returnURL: store.getters.returnUrl,
          customerId: store.getters.customerID,
          registerId: store.getters.registerID,
          shopId: store.getters.shopID,
        },
      })
      .then((response) => {
        commit("setSale", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setCustomer({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/customers", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
          customerId: store.getters.customerID,
        },
      })
      .then((response) => {
        commit("setCustomer", response.data);
        if (response.data.email) {
          commit("setEmail", response.data.email);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        commit("setErrorMessage", {
          name: "customer",
          message: error.response.data.message,
        });
      });
  },
  setSettings({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/appSettings", {
        params: {
          shopKey: store.getters.shopKey,
        },
      })
      .then((response) => {
        commit("setApplicationSettings", response.data);
        commit("setSendMail", response.data.automatically_send_email === 1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setSaleLines({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/saleLines", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
          accountId: store.getters.accountID,
          employeeId: store.getters.employeeID,
          returnURL: store.getters.returnUrl,
          customerId: store.getters.customerID,
          registerId: store.getters.registerID,
          shopId: store.getters.shopID,
        },
      })
      .then((response) => {
        commit("setSaleLines", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setPaymentMethods({ commit, state }) {
    let url = "";
    if (state.isResto) {
      url = "/api/settings/payment-methods?shopKey=" + state.shopKey;
      axios
        .get(url)
        .then(function(response) {
          commit("setPaymentMethods", response.data.methods);
          commit("setWallee", response.data.hasWallee);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      url = "/api/paymentMethods";
      axios
        .get(url, {
          params: {
            saleId: store.getters.saleID,
            shopKey: store.getters.shopKey,
          },
        })
        .then(function(response) {
          commit("setPaymentMethods", response.data.methods);
          commit("setWallee", response.data.hasWallee);
          commit("subtractMoney", response.data.methods);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  setTerminalPayments({ commit }) {
    axios
      .get("/api/terminalPayments", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
        },
      })
      .then(function(response) {
        commit("setTerminalPayments", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setLoyaltyEvents({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/loyaltyEvents", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
          accountId: store.getters.accountID,
        },
      })
      .then(function(response) {
        commit("setLoyaltyEvents", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setShop({ commit, state }) {
    if (state.isResto) {
      return;
    }
    axios
      .get("/api/shops/", {
        params: {
          shopId: store.getters.shopID,
          shopKey: store.getters.shopKey,
        },
      })
      .then(function(response) {
        commit("setShop", response.data.shopData);
        commit("setUser", response.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getCcvPayUrl({ commit, state }, amount) {
    axios
      .post(
        "/api/payments",
        { amount },
        {
          params: {
            ...state.urlParams,
            processor: "ccv",
          },
        }
      )
      .then((response) => {
        console.log(response.data.original.payUrl);
        commit("setCcvPayUrl", response.data.original.payUrl);

        Promise.resolve(response);
      })
      .catch(({ response }) => {
        console.log(response);

        Promise.reject(response.message);
      });
  },
  useLoyaltyAmount({ state }, amount) {
    state.isLoyaltyRedeemLoading = true;
    axios
      .post("/api/useLoyalty", {
        saleData: state.saleData,
        shopKey: state.shopKey,
        loyaltyAmount: amount,
      })
      .then(function(response) {
        console.log(response);
        state.isLoyaltyRedeemLoading = false;
        store.dispatch("setCustomer");
        store.dispatch("setSaleLines");
        store.dispatch("setSale");
        state.loyaltyUsed = true;
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Loyalty credits used!",
        });
      })
      .catch(function(error) {
        state.isLoyaltyRedeemLoading = false;
        console.log(error.response.data);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: error.response.data.message,
        });
      });
  },
  useLoyaltyPoints({ state }, points) {
    state.isLoyaltyRedeemLoading = true;
    axios
      .post("/api/useLoyalty", {
        saleData: state.saleData,
        shopKey: state.shopKey,
        loyaltyPoints: points,
      })
      .then(function(response) {
        console.log(response);
        state.isLoyaltyRedeemLoading = false;
        store.dispatch("setCustomer");
        store.dispatch("setSaleLines");
        store.dispatch("setSale");
        state.loyaltyUsed = true;
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Loyalty points used!",
        });
      })
      .catch(function(error) {
        console.log(error.response.data);
        state.isLoyaltyRedeemLoading = false;
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: error.response.data.message,
        });
      });
  },
  addLoyaltyAmount({ state }, amount) {
    state.isLoyaltyAddedLoading = true;
    axios
      .post("/api/addLoyalty", {
        saleData: state.saleData,
        shopKey: state.shopKey,
        loyaltyAmount: amount,
      })
      .then(function(response) {
        console.log(response);
        state.isLoyaltyAddedLoading = false;
        store.dispatch("setCustomer");
        store.dispatch("setLoyaltyEvents");
        state.loyaltyAdded = true;
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Loyalty added to customer!",
        });
      })
      .catch(function(error) {
        console.log(error.response.data);
        state.isLoyaltyAddedLoading = false;
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: error.response.data.message,
        });
      });
  },
  addLoyaltyPoints({ state }, points) {
    state.isLoyaltyAddedLoading = true;
    axios
      .post("/api/addLoyalty", {
        saleData: state.saleData,
        shopKey: state.shopKey,
        loyaltyPoints: points,
      })
      .then(function(response) {
        console.log(response);
        state.isLoyaltyAddedLoading = false;
        state.loyaltyAdded = true;
        store.dispatch("setCustomer");
        store.dispatch("setLoyaltyEvents");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Loyalty points added to customer!",
        });
      })
      .catch(function(error) {
        console.log(error.response.data);
        state.isLoyaltyAddedLoading = false;
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: error.response.data.message,
        });
      });
  },

  checkLoyalty({ state }) {
    if (!state.isResto) {
      axios
        .get("/api/hasLoyalty", {
          params: {
            saleId: state.saleID,
            shopKey: state.shopKey,
            accountId: state.accountID,
          },
        })
        .then(function(response) {
          // handle success
          state.loyaltyAdded = response.data;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });

      store.dispatch("setLoyaltyEvents");
    }
  },

  ccvPaymentSuccessful({ commit }, data) {
    // commit("setCcvAmount", data.data.amount);
    // commit("setCcvStatus", "Success");
    // commit("setCanFinalize", true);
    commit("showCcvModal", false);
    commit("addNewCCVPaymentMethod", data.data.amount);
  },
};
