module.exports = {
  saleId: {
    "en-GB": "Sale-ID",
    "nl-BE": "Verkoop-ID",
  },
  details: {
    "en-GB": "Details",
    "nl-BE": "Details",
  },
  PRODUCT: {
    "en-GB": "PRODUCT",
    "nl-BE": "PRODUCT",
  },
  TOTAL: {
    "en-GB": "TOTAL",
    "nl-BE": "TOTAL",
  },
  SaleAlreadyCompleted: {
    "en-GB":
      "Sale is already complete, please return to Lightspeed! You can view the invoice at the MDware dashboard.",
    "nl-BE":
      "Verkoop is al afgerond, gelieve terug te keren naar Lightspeed! Je kan de factuur zien op het MDware dashboard",
  },
  ReturnLS: {
    "en-GB": "Return to Lightspeed",
    "nl-BE": "Terug naar Lightspeed",
  },
  AppliedDiscounts: {
    "en-GB": "Applied discounts",
    "nl-BE": "Toegepaste kortingen",
  },
  items: {
    "en-GB": "items",
    "nl-BE": "artikelen",
  },
  discount: {
    "en-GB": "discount",
    "nl-BE": "korting",
  },
  Discount: {
    "en-GB": "Discount",
    "nl-BE": "Korting",
  },
  VAT: {
    "en-GB": "VAT",
    "nl-BE": "BTW",
  },
  SaleAlreadyCompletedTitle: {
    "en-GB": "Sale already completed",
    "nl-BE": "Verkoop al afgerond",
  },
  Payment: {
    "en-GB": "Payment",
    "nl-BE": "Betaling",
  },
  PAYMENT: {
    "en-GB": "PAYMENT",
    "nl-BE": "BETALING",
  },
  BALANCE: {
    "en-GB": "BALANCE",
    "nl-BE": "BALANS",
  },
  Balance: {
    "en-GB": "Balance",
    "nl-BE": "Balans",
  },
  Change: {
    "en-GB": "Change",
    "nl-BE": "Wisselgeld",
  },
  CHANGE: {
    "en-GB": "CHANGE",
    "nl-BE": "WISSELGELD",
  },
  BackToSale: {
    "en-GB": "Back to sale",
    "nl-BE": "Terug naar verkoop",
  },
  StartCCV: {
    "en-GB": "Start CCV Payment",
    "nl-BE": "Start CCV Betaling",
  },
  ConvertSaleToInvoice: {
    "en-GB": "Convert sale to invoice?",
    "nl-BE": "Verkoop in factuur omzetten?",
  },
  Comment: {
    "en-GB": "Add a comment",
    "nl-BE": "Voeg een opmerking toe",
  },
  CustomerLoading: {
    "en-GB": "Customer information is still loading",
    "nl-BE": "Klant informatie is aan het laden",
  },
  NonValidRetailCustomer: {
    "en-GB": "Not a valid retail customer",
    "nl-BE": "Klant ontbreekt retail gegevens",
  },
  Giftcard: {
    "en-GB": "Giftcard",
    "nl-BE": "Cadeaukaart",
  },
  AddGiftcard: {
    "en-GB": "Add Giftcard",
    "nl-BE": "Cadeaukaart toevoegen",
  },
  INVOICING: {
    "en-GB": "INVOICING",
    "nl-BE": "FACTURATIE",
  },
  Comments: {
    "en-GB": "Comments",
    "nl-BE": "Opmerkingen",
  },
  SendInvoice: {
    "en-GB": "Send this invoice to customer?",
    "nl-BE": "Factuur opsturen naar de klant",
  },
  Invoice: {
    "en-GB": "Invoice",
    "nl-BE": "Factuur",
  },
  Receipt: {
    "en-GB": "Receipt",
    "nl-BE": "Kasticket",
  },
  CCVCharged: {
    "en-GB": "CCV charged",
    "nl-BE": "CCV betaald",
  },
  CCVFailed: {
    "en-GB": "CCV payment failed",
    "nl-BE": "CCV betaling mislukt",
  },
};
