import Swal from "sweetalert2";
import { last } from 'lodash';
import { round } from "../helpers/round.js";
import { roundTo5Cents } from "../helpers/roundTo5Cents";

export default {
  setCustomer: (state, customer) => {
    state.customer = customer;
  },
  setUrlParams: (state, params) => {
    state.urlParams = params;
  },
  setCcvPayUrl: (state, url) => {
    state.ccvPayUrl = url;
  },
  setErrorMessage(state, data) {
    state[data.name].errorMessage = data.message;
    state[data.name].loading = false;
  },
  setApplicationSettings: (state, settings) => {
    state.applicationSettings = settings;
  },
  setParamsInitialized: (state, initialized) => {
    state.paramsInitialized = initialized;
  },
  setResto: (state, isResto) => {
    state.isResto = isResto;
  },
  setSale: (state, sale) => {
    state.sale = sale;
    state.balance = round(sale["balance"]);
    state.hasSpecialItems = sale["hasSpecialItems"];
  },
  setSaleID: (state, saleID) => {
    state.saleID = saleID;
    state.saleData["saleID"] = saleID;
  },
  setShopKey: (state, shopKey) => {
    state.shopKey = shopKey;
    state.saleData["shopKey"] = shopKey;
  },
  setUser: (state, user) => {
    state.user = user;
  },
  setType: (state, type) => {
    state.type = type;
    state.saleData["type"] = type;
  },
  setAccountID: (state, accountID) => {
    state.accountID = accountID;
    state.saleData["accountID"] = accountID;
  },
  setSystemUserID: (state, systemUserID) => {
    state.systemUserID = systemUserID;
    state.saleData["systemUserID"] = systemUserID;
  },
  setEmployeeID: (state, employeeID) => {
    state.employeeID = employeeID;
    state.saleData["employeeID"] = employeeID;
  },
  setCustomerID: (state, customerID) => {
    state.customerID = customerID;
    state.saleData["customerID"] = customerID;
  },
  setShopID: (state, shopID) => {
    state.shopID = shopID;
    state.saleData["shopID"] = shopID;
  },
  setRegisterID: (state, registerID) => {
    state.registerID = registerID;
    state.saleData["registerID"] = registerID;
  },
  setReturnUrl: (state, url) => {
    state.returnUrl = url;
    state.saleData["returnUrl"] = url;
  },
  setSaleData: (state, saleData) => {
    state.saleData = saleData;
  },
  setSaleLines: (state, saleLines) => {
    state.saleLines = saleLines;
  },
  setPaymentMethods: (state, methods) => {
    state.paymentMethods = methods;

    const ccvPaymentMethods = state.paymentMethods.filter((element) => element.processor === "ccv");

    if ((last(ccvPaymentMethods) || {}).charged) {
      const lastCcv = last(ccvPaymentMethods);

      state.ccvPayment.amount = parseFloat(lastCcv.value);
      state.ccvPayment.status = "Success";
      state.canFinalize = parseFloat(lastCcv.value) >= state.balance;
      // state.paymentMethods.push({
      //   ...firstCcv,
      //   newPaymentTypeId: `${firstCcv.paymentTypeID}-${ccvPaymentMethods.length}`,
      //   charged: false,
      //   value: null,
      // });
    }
  },
  setTerminalPayments: (state, payments) => {
    state.terminalPayments = payments;
  },
  subtractMoneyCash: (state, methods) => {
    for (let method of methods) {
      if (method.value == "." || method.value == ",") {
        return (method.value = "");
      }
      if (
        round(method.value) > round(state.balance) &&
        (method.paymentTypeID ==
          state.applicationSettings.integration_retail_rounded_payment_1_id ||
          (state.applicationSettings.cash_rounding_enabled &&
            method.paymentTypeID ==
              state.applicationSettings.integration_retail_paid_payment_1_id))
      ) {
        state.change = round(method.value - state.balance);

        if (state.change.toString().includes(".")) {
          let decimals = state.change.toString().split(".")[1];
          if (decimals.toString().length === 1) decimals = decimals * 10;
          let rest = decimals % 5;
          if (rest >= 3) {
            state.roundedAmount = ((5 - rest) / -100).toString();
            state.change = roundTo5Cents(state.change);
          } else if (rest < 3) {
            state.roundedAmount = (rest / 100).toString();
            state.change = roundTo5Cents(state.change);
          }
        }

        /* if (state.roundedAmount !== "") {
            if (round(state.roundedAmount) > 0.0) {
              //state.change += Math.abs(round(state.roundedAmount));
            } else {
              state.change -= Math.abs(round(state.roundedAmount));
            }
          } */
        //method.value = state.balance;
        state.balance = 0;
      } else if (round(method.value) <= round(state.balance)) {
        state.balance = round(state.balance) - round(method.value);
        state.balance = round(state.balance);
      } else if (
        round(method.value) > round(state.balance) &&
        method.paymentTypeID ==
          state.applicationSettings.integration_retail_paid_payment_1_id
      ) {
        state.change = round(method.value) - round(state.balance);
        state.balance = 0.0;
      }
    }
  },
  subtractMoney: (state, methods) => {
    state.change = 0;
    //state.balance = round(state.sale.displayableTotal);
    //state.balance = round(state.sale["balance"]) - round(state.sale["totalDiscountAmount"]);

    //state.balance = state.sale["total"];
    if (state.hasSpecialItems) {
      if (round(state.sale["balance"]) > 0) {
        state.balance =
          round(state.sale["balance"]) -
          Math.abs(round(state.sale["totalDiscountAmount"]));
      } else {
        state.balance = round(state.sale["balance"]);
      }
    } else {
      state.balance = round(state.sale["totalDue"]);
    }

    for (let method of methods) {
      if (
        method.value !== "0" &&
        method.value !== "" &&
        method.value !== "." &&
        String(method.value).slice(-2) !== ".0" &&
        !isNaN(round(method.value))
      ) {
        if (
          round(method.value) <=
          round(state.balance) +
            round(state.change) +
            round(state.roundedAmount)
        ) {
          state.balance = round(state.balance) - round(method.value);
          state.balance = round(state.balance);
        } else if (
          round(method.value) >
            round(state.balance) +
              round(state.change) +
              round(state.roundedAmount) &&
          state.change == 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The number you inserted is bigger than the balance!",
          });
          //reset value to null
          method.value = "";
        }

        //update change if there is already change value
        if (
          round(state.change) > 0 &&
          !(
            method.paymentTypeID ==
            state.applicationSettings.integration_retail_rounded_payment_1_id
          )
        ) {
          state.change = round(state.change) + round(method.value);
        }
      } else if (method.value == "." || method.value == ",") {
        method.value = "";
      } else {
        console.log("wrong calculation");
        console.log(round(method.value), round(state.balance), method.name);
      }
    }
    for (let giftcard of Object.values(state.giftCardData)) {
      if (
        giftcard.amount &&
        !isNaN(giftcard.amount) &&
        Math.abs(round(giftcard.amount)) <= round(state.balance)
      ) {
        state.balance = round(state.balance - Math.abs(giftcard.amount));
      }
    }

    if (state.roundedAmount !== "" && round(state.roundedAmount) <= 0.0) {
      state.balance =
        round(state.balance) - Math.abs(round(state.roundedAmount));
    }
  },
  setLoyaltyEvents: (state, loyaltyEvents) => {
    state.loyaltyEvents = loyaltyEvents;
  },
  setShop: (state, shop) => {
    state.shop = shop;
  },
  showFinalizeButtons: (state, value) => {
    state.showFinalizeButtons = value;
  },
  setWallee: (state, value) => {
    state.hasWallee = value;
  },
  addGiftCardData: (state, payload) => {
    let index = state.giftCardData
      .map(function(e) {
        return e.code;
      })
      .indexOf(payload.code);
    if (index > -1) {
      const giftCard = state.giftCardData[index];
      giftCard.amount += round(payload.value);
      state.balance -= Math.abs(round(payload.value));
    } else {
      state.giftCardData.push({
        code: payload.code,
        amount: Math.abs(round(payload.value)),
        creditAccountID: payload.creditAccountID,
      });
      state.balance -= Math.abs(round(payload.value));
    }

    //update paymentmethods value of giftcard
    const giftCardPayment = state.paymentMethods.find(
      (element) => element.paymentTypeID == "5"
    );
    giftCardPayment.value = JSON.stringify(JSON.stringify(state.giftCardData));
  },
  deleteGiftCard: (state, code) => {
    const giftCard = state.giftCardData.find((element) => element.code == code);
    state.balance += Math.abs(giftCard.amount);

    var cardIndex = state.giftCardData.indexOf(giftCard);
    state.giftCardData.splice(cardIndex, 1);
  },
  subtractAmounts: (state, amounts) => {
    //state.balance = state.sale.displayableTotal;
    /* state.balance =
      state.sale["total"] != "0"
        ? round(state.sale["displayableTotal"]) +
          Math.abs(round(state.sale["calcPayments"]))
        : round(state.sale["displayableTotal"]); */
    /* if (round(state.sale["balance"]) > 0) {
      state.balance =
        round(state.sale["balance"]) -
        Math.abs(round(state.sale["totalDiscountAmount"]));
    } else {
      state.balance = round(state.sale["balance"]);
    } */
    if (state.hasSpecialItems) {
      if (round(state.sale["balance"]) > 0) {
        state.balance =
          round(state.sale["balance"]) -
          Math.abs(round(state.sale["totalDiscountAmount"]));
      } else {
        state.balance = round(state.sale["balance"]);
      }
    } else {
      state.balance = round(state.sale["totalDue"]);
    }
    for (const [key, value] of Object.entries(amounts)) {
      if (
        value.amount &&
        !isNaN(value.amount) &&
        value.amount <= state.balance
      ) {
        state.balance -= value.amount;
        const payment = state.paymentMethods.find(
          (paymentMethod) => paymentMethod.paymentTypeID === key
        );
        payment.value = value.amount;
      }
    }
  },
  saveNote: (state, note) => {
    state.note = note;
  },
  setRoundedAmount: (state, amount) => {
    state.roundedAmount = amount;
  },
  setChange: (state, amount) => {
    state.change = amount;
  },
  setCcvAmount: (state, amount) => {
    state.ccvPayment.amount = amount;
  },
  showCcvModal: (state, bool) => {
    state.ccvPayment.showModal = bool;
  },
  setCanFinalize: (state, bool) => {
    state.canFinalize = bool;
  },
  setSendMail: (state, bool) => {
    state.sendMail = bool;
  },
  setCcvStatus: (state, status) => {
    state.ccvPayment.status = status;
  },
  setEmail: (state, email) => {
    state.email = email;
  },
  setEmail2: (state, email) => {
    state.email2 = email;
  },
  addNewCCVPaymentMethod: (state, lastPaymentAmount) => {
    console.log('addNewCCVPaymentMethod', state);
    const ccvIndex = state.paymentMethods.findIndex((method) => method.processor === "ccv");
    // const ccvPaymentMethods = state.paymentMethods.filter((method) => method.processor === "ccv");
    // const firstCcvPayment = first(ccvPaymentMethods);
    state.paymentMethods[ccvIndex].charged = true;
    state.paymentMethods[ccvIndex].value = lastPaymentAmount;

    // const lastCcvPayment = last(ccvPaymentMethods);

    // lastCcvPayment.value = lastPaymentAmount;
    // lastCcvPayment.charged = true;

    // state.paymentMethods.push({
    //   name: 'CCV',
    //   processor: 'ccv',
    //   charged: true,
    //   value: lastPaymentAmount,
    //   newPaymentTypeId: `${firstCcvPayment.paymentTypeID}-${ccvPaymentMethods.length}`,
    // });

    // ccvPaymentMethods.forEach((method) => {
    //   method["showCCV"] = false;
    // });
  },
};
