<template>
    <div class="mt-4">
        <div v-if="paymentMethods.length > 0">
            <div class="w-full">
                <div
                    v-for="payment in paymentMethods"
                    :key="payment.paymentTypeID"
                    :class="['px-1', onlyReceipt ? 'w-17/20 mx-auto' : '']"
                >
                    <div class="flex justify-between mt-2 bg-gray-50 rounded items-center">
                        <p class="w-2/3 pl-2 py-3"
                           :class="{'cursor-pointer': canFillMoney(balance, payment)}"
                           @click="fillMoney(payment, balance, paymentMethods)">
                            {{ payment.name }}
                        </p>

                        <span class="w-1/3">
                            {{ invoiceCurrency }}

                            <input
                                class="w-4/6 bg-gray-50 py-2"
                                type="text"
                                v-model="payment.value"
                                :placeholder="(0.0).toFixed(2)"
                                @keypress="isNumber($event)"
                                @input="subtractMoney()"
                                @blur="roundAllMethods()"
                                :disabled="!canFillMoney(balance, payment)"
                            />
                        </span>

                        <button
                            class="bg-blue-100 p-6 flex items-center rounded text-blue-800 font-medium cursor-pointer disabled:cursor-default disabled:opacity-50"
                            :disabled="!canFillMoney(balance, payment)"
                            @click="fillMoney(payment, balance, paymentMethods)"
                        >
                            {{ $t("max") }}.
                        </button>
                    </div>
                </div>

                <!-- ccv payments -->
                <div>
                    <div class="px-1" :class="{'w-17/20 mx-auto': onlyReceipt}"
                         v-for="payment in ccvPaymentMethods"
                         :key="payment.newPaymentTypeID ? payment.newPaymentTypeID : payment.paymentTypeID"
                    >
                        <div>
                            <p class="bg-green-500 rounded text-white p-2 my-1" v-if="payment.charged">
                                {{ invoiceCurrency }}{{ payment.value }} CCV charged
                            </p>

                            <div class="flex justify-between mt-2 bg-gray-50 rounded items-center">
                                <p class="w-2/3 pl-2 py-3"
                                   :class="{'cursor-pointer': canFillMoney(balance, payment)}"
                                   @click="fillMoney(payment, balance, paymentMethods)">
                                    {{ payment.name }}
                                </p>

                                <span class="w-1/3">
                                    {{ invoiceCurrency }}

                                    <input
                                        class="w-4/6 bg-gray-50 py-2"
                                        type="text"
                                        v-model="payment.value"
                                        :placeholder="(0.0).toFixed(2)"
                                        @keypress="isNumber($event)"
                                        @input="subtractMoney()"
                                        @blur="roundAllMethods()"
                                        :disabled="!canFillMoney(balance, payment)"
                                    />
                                </span>

                                <button
                                    class="bg-blue-100 p-6 flex items-center rounded text-blue-800 font-medium cursor-pointer disabled:cursor-default disabled:opacity-50"
                                    :disabled="!canFillMoney(balance, payment)"
                                    @click="fillMoney(payment, balance, paymentMethods)"
                                >
                                    {{ $t("max") }}.
                                </button>
                            </div>
                        </div>

                        <!-- actions -->
                        <div v-if="showCCV && ccvPayment.amount > 0">
                            <p
                                class="cursor-pointer p-2 text-white bg-blue-800 my-2 rounded"
                                @click="startCCVPayment()"
                            >
                                Start CCV Payment
                            </p>
                        </div>

                        <div v-if="ccvPayment.errorMessage !== '' && ccvPayment.status == 'Failed' && showCcvInfo">
                            <p class="bg-red-100 rounded text-gray-500 p-2 my-1">
                                CCV payment failed
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <GiftcardsWrapper class="mt-3 px-1"></GiftcardsWrapper>
        </div>
        <div v-else class="mt-1 py-2 px-1 bg-gray-50 rounded items-center">
            <p class="text-sm">
                You are limited to MDware payment methods but you have not yet created
                them.
            </p>
            <a href="https://app.mdware.org/settings" class="my-2">
                <p class="font-semibold text-blue-950 hover:text-black">
                    Please click here to go to your settings.
                </p>
            </a>
        </div>

        <CCVModal :amount="ccvAmount.value" v-if="showCcvModal"></CCVModal>
    </div>
</template>

<script>
import {ref} from "vue";
import {mapGetters} from "vuex";
import store from "@/store/store";
import GiftcardsWrapper from "@/components/Content/Payment/GiftcardsWrapper";
import CCVModal from "./CCVModal.vue";
import {round} from "../../../helpers/round";
import {roundTo5Cents} from "../../../helpers/roundTo5Cents";

export default {
    name: "PaymentMethods",
    components: {GiftcardsWrapper, CCVModal},
    setup() {
        let saldo = ref(0);
        let ccvAmount = ref(0);
        let showCCV = ref(false);
        let payUrl = ref(false);
        let showCcvInfo = ref(true);

        const isNumber = (evt) => {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46 &&
                charCode !== 44
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        };

        const subtractMoney = () => {
            let methods = store.getters.paymentMethods;
            store.commit("setRoundedAmount", "");
            methods.forEach((method) => {
                /* check for , and ., but not with giftcards since that
                data is stored as json and so the backend cant parse it if we replace with . */
                if (method.value.includes(",") && method.paymentTypeID != "5")
                    method.value = method.value.replace(",", ".");
                if (
                    (method.paymentTypeID ==
                        store.getters.applicationSettings
                            .integration_retail_rounded_payment_1_id ||
                        (store.getters.applicationSettings.cash_rounding_enabled &&
                            method.paymentTypeID ==
                            store.getters.applicationSettings
                                .integration_retail_paid_payment_1_id)) &&
                    method.value.slice(-1) !== "." &&
                    method.value.slice(-2) !== ".0" &&
                    method.value !== "0.0" &&
                    round(store.getters.balance) - round(method.value) < 0.05
                ) {
                    cashRounding(method);
                }

                if (method.processor == "ccv") handleCCV(method);
            });
            let cashMethods = methods.filter(isCash);
            let nonCashMethods = methods.filter(isNotCash);

            store.commit("subtractMoney", nonCashMethods);
            store.commit("subtractMoneyCash", cashMethods);
        };

        function isCash(method) {
            return (
                method.paymentTypeID ==
                store.getters.applicationSettings
                    .integration_retail_paid_payment_1_id &&
                method.value !== "0" &&
                method.value !== "" &&
                method.value !== "."
            );
        }

        function isNotCash(method) {
            return (
                !(
                    method.paymentTypeID ==
                    store.getters.applicationSettings.integration_retail_paid_payment_1_id
                ) &&
                method.value !== "0" &&
                method.value !== "" &&
                method.value !== "."
            );
        }

        const roundAllMethods = () => {
            let methods = store.getters.paymentMethods;
            methods.forEach((method) => {
                if (
                    method.value !== "0" &&
                    method.value !== "" &&
                    method.value !== "." &&
                    !isNaN(method.value)
                ) {
                    method.value = round(method.value).toFixed(2);
                }
            });
            let cashMethods = methods.filter(isCash);
            let nonCashMethods = methods.filter(isNotCash);

            store.commit("subtractMoney", nonCashMethods);
            store.commit("subtractMoneyCash", cashMethods);
        };

        const canFillMoney = (balance, payment) => {
            if (payment.processor === "ccv") {
                const {status} = store.getters.ccvPayment

                if (status == "Success" || status == "Charged") {
                    return false;
                }
            }

            return !(isNaN(payment.value) || payment.value == "0.");
            /*if (round(balance) > 0.0) {
                return true;
            }

            return (!(isNaN(payment.value) || !round(payment.value) > 0.0) || payment.value == "0.");*/
            // return (parseFloat(balance) > 0.009) || (parseFloat(balance) > 0.009 && (isNaN(value) || !parseFloat(value) > 0.009))
        };

        const fillMoney = (payment, bal) => {
            if (!canFillMoney(bal, payment)) {
                return;
            }

            let methods = store.getters.paymentMethods;
            let floatValue = 0;
            let balance = round(bal);
            floatValue = round(payment.value);

            if (!isNaN(floatValue)) floatValue += balance;
            else floatValue = balance;

            //value are stored as strings for the post request to lightspeed
            payment.value = floatValue.toFixed(2);

            if (
                payment.paymentTypeID ==
                store.getters.applicationSettings
                    .integration_retail_rounded_payment_1_id ||
                (store.getters.applicationSettings.cash_rounding_enabled &&
                    payment.paymentTypeID ==
                    store.getters.applicationSettings
                        .integration_retail_paid_payment_1_id)
            ) {
                cashRounding(payment);
            }
            let cashMethods = methods.filter(isCash);
            let nonCashMethods = methods.filter(isNotCash);

            store.commit("subtractMoney", nonCashMethods);
            store.commit("subtractMoneyCash", cashMethods);

            roundAllMethods();

            if (payment.processor === "ccv") handleCCV(payment);
        };

        const cashRounding = (payment) => {
            if (!payment.value.includes(".")) return;
            let decimals = payment.value.split(".")[1];
            if (decimals.toString().length === 1) decimals = decimals * 10;
            let rest = decimals % 5;
            console.log("rest", rest);

            //create factor that make a positive or negative roundedAmount according to credit nota
            let factor = store.getters.balance < 0 ? -1 : 1;

            if (rest >= 3) {
                store.commit(
                    "setRoundedAmount",
                    (((5 - rest) / 100) * factor).toString()
                );
                payment.value = roundTo5Cents(payment.value).toString();
            } else if (rest < 3) {
                store.commit("setRoundedAmount", ((rest / -100) * factor).toString());
                payment.value = roundTo5Cents(payment.value).toString();
            }
        };

        const handleCCV = (payment) => {
            if (payment.value == "") {
                store.commit("setCanFinalize", true);
                showCCV.value = false;
                return;
            }
            store.commit("setCcvAmount", round(payment.value));

            if (
                store.getters.ccvPayment.status !== "Success" &&
                store.getters.ccvPayment.status !== "Charged"
            ) {
                store.commit("setCanFinalize", false);
            }
            ccvAmount.value = payment.value;
            console.log(ccvAmount.value);
            showCCV.value = true;
        };
        const startCCVPayment = () => {
            showCcvInfo.value = false;
            let amount = ccvAmount.value;
            showCCV.value = true;

            store.commit("setCcvPayUrl", "");
            store.commit("setErrorMessage", {
                name: "ccvPayment",
                message: "",
            });
            store.dispatch("getCcvPayUrl", amount);
            store.commit("showCcvModal", true);
            showCcvInfo.value = true;
        };

        return {
            subtractMoney,
            fillMoney,
            payUrl,
            canFillMoney,
            saldo,
            showCCV,
            startCCVPayment,
            ccvAmount,
            roundAllMethods,
            showCcvInfo,
            isNumber,
        };
    },
    computed: {
        ...mapGetters([
            "invoiceCurrency",
            "sale",
            "balance",
            "ccvPayUrl",
            "urlParams",
            "onlyReceipt",
            "roundedAmount",
            "ccvPayment",
            "showCcvModal",
            "canFinalize",
        ]),
        normalizeValue: {
            get() {
                return this.foo + 1;
            },

            set(value) {
                this.foo = value - 1;
            },
        },
        paymentMethods: {
            get() {
                return store.getters.paymentMethods.filter(
                    (payment) => payment.name != "Gift Card" && payment.processor !== "ccv"
                );
            },
        },
        ccvPaymentMethods() {
            return store.getters.paymentMethods
                .filter((payment) => payment.processor === "ccv")
                .sort((a, b) => (a === b ? 0 : a ? 1 : -1)); //true value first
        },
    },
};
</script>

<style scoped></style>
