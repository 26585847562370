<template>
  <div class="p-5">
    <div class="tabs text-left flex justify-start border-b-2 border-gray-400">
      <p
        v-if="applicationSettings.loyalty_enabled"
        v-on:click="activeTab = 1"
        v-bind:class="[activeTab === 1 ? 'active' : '']"
        class="cursor-pointer p-1 uppercase"
      >
        {{ $t("loyalty") }}
      </p>
      <p
        v-if="applicationSettings.retail_quoting_enabled"
        v-on:click="activeTab = 2"
        v-bind:class="[activeTab === 2 ? 'active' : '']"
        class="cursor-pointer p-1 uppercase"
      >
        {{ $t("quoting") }}
      </p>
    </div>

    <div class="content">
      <Loyalty
        v-if="applicationSettings.loyalty_enabled"
        v-show="activeTab === 1"
      ></Loyalty>
      <Quoting
        v-if="applicationSettings.retail_quoting_enabled"
        v-show="activeTab === 2"
      ></Quoting>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { mapGetters } from "vuex";
import Loyalty from "@/components/Content/RightPanel/Loyalty/Loyalty";
import Quoting from "@/components/Content/RightPanel/Quoting/Quoting";
import store from "../../../store/store";

export default {
  name: "RightPanel",
  components: { Quoting, Loyalty },
  computed: {
    ...mapGetters(["applicationSettings"]),
  },
  setup() {
    const activeTab = ref(null);

    onBeforeMount(() => {
      if (store.getters.applicationSettings.loyalty_enabled) {
        activeTab.value = 1;
      } else if (store.getters.applicationSettings.retail_quoting_enabled) {
        activeTab.value = 2;
      }
    });
    return {
      activeTab,
    };
  },
};
</script>

<style scoped>
.active {
  color: #1a202c;
  border-bottom: #1a202c 2px;
}
</style>
