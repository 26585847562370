<template>
  <div class="mx-auto my-2 w-11/12">
    <div class="my-3 flex justify-between">
      <div>
        <p class="text-xs font-medium">
            {{ lang["saleId"][language] }}
        </p>
        
        <p class="text-sm font-semibold">
            {{ sale.saleID }}
        </p>
      </div>
      
      <SaleDetailsModal></SaleDetailsModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SaleDetailsModal from "@/components/LeftSidebar/SaleDetails/SaleDetailsModal";
import lang from "../../../assets/lang";

export default {
  name: "SaleDetails",
  components: { SaleDetailsModal },
  data() {
    return {
      lang: lang,
    };
  },
  setup() {},
  computed: {
    ...mapGetters(["sale", "saleData", "invoiceCurrency", "language"]),
  },
};
</script>

<style scoped>
</style>
