<template>
  <!-- convert to quote button -->
  <div
    class="flex justify-around items-center bg-blue-300 rounded-md px-4 w-1/2 py-2 cursor-pointer max-h-9 my-3"
    @click="toggleModal"
  >
    <p class="text-blue-600 text-center text-sm font-semibold">
      Convert to quote
    </p>
    <box-icon
      class="ml-1 max-h-5"
      color="#17A2B8"
      type="solid"
      name="chevron-right"
    ></box-icon>
  </div>
  <!-- convert to quote button End -->
  <!--Modal-->
  <vue-final-modal
    :name="'QuotingItemsModal'"
    v-model="showModal"
    content-class="bg-white p-8 w-5/6 md:max-w-modal rounded-lg mx-auto relative"
  >
    <div class="">
      <!-- Header-->
      <div class="modal-header border-b-2 border-gray-300">
        <p class="my-2 text-lg text-left">Apply Discount</p>
        <button
          class="absolute top-0 right-0 mt-2 mr-2"
          @click="showModal = false"
        >
          <box-icon name="x-circle"></box-icon>
        </button>
      </div>
      <!-- Header End-->

      <!-- body-->
      <div class="modal-body p-1 m-1 bg-grey-200 flex flex-col p-2 mb-2 ">
        <!--Discounts Row -->
        <div class="bg-gradient-lighter mb-2 flex p-2 " v-if="discounts">
          <div class="w-1/5">
            <label class="mt-3 text-capitalize">Discounts</label>
          </div>
          <div class="w-4/5">
            <select
              class="w-full border rounded"
              v-model="selectedDiscount"
              v-on:change="discountChanged"
            >
              <option value="0" :selected="selectedDiscount == 0"
                >Default/None</option
              >
              <option
                v-for="discount in discounts"
                :key="discount.discountID"
                :value="discount.discountID"
                :selected="selectedDiscount == discount.discountID"
                >{{ discount.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-else class="bg-gradient-lighter mb-2 flex p-2 ">
          <div class="loader-nwh w-5 h-5 "></div>
        </div>
        <div class="h-96 overflow-y-auto">
          <QuotingTable ref="quotingTable" :isTaxInclusive="isTaxInclusive" />
        </div>
        <!--Discounts Row End-->
        <!-- table-->
      </div>
      <!-- body end-->
      <!-- Footer -->
      <div>
        <div
          class="flex justify-around items-center bg-blue-300 rounded-md px-4 w-1/2 py-2 cursor-pointer max-h-9 my-3"
          @click="convertToQuote"
        >
          <p class="text-blue-600 text-center text-sm font-semibold">
            Apply & Convert To Quote
          </p>
          <box-icon
            class="ml-1 max-h-5"
            color="#17A2B8"
            type="solid"
          ></box-icon>
        </div>
      </div>
      <!-- Footer End-->
    </div>
  </vue-final-modal>
</template>

<script>
// import { ref } from "vue";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import QuotingTable from "./QuotingTable.vue";

export default {
  name: "QuotingItemsModalContainer",
  components: { QuotingTable },
  data() {
    return {
      showModal: false,
      discounts: null,
      selectedDiscount: 0,
      isTaxInclusive: true,
    };
  },
  computed: {
    ...mapGetters(["saleLines", "shopKey", "sale", "customer"]),
    ...mapState(["shop"]),
  },
  watch: {
    shop() {
      this.getUserTaxInclusive();
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    getUserDiscounts() {
      axios
        .get("/api/discounts", {
          params: {
            shopKey: this.shopKey,
          },
        })
        .then((response) => {
          this.discounts = response.data;
          this.discountChanged();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getUserTaxInclusive() {
      if (!this.shop.shopID) return;
      axios
        .get("/api/userTaxInclusive", {
          params: {
            shopKey: this.shopKey,
            shop: this.shop,
          },
        })
        .then((response) => {
          this.isTaxInclusive = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    setSelectedDiscount() {
      if (this.sale.Customer && this.sale.Customer.discountID) {
        this.selectedDiscount = this.sale.Customer.discountID;
      }
    },
    discountChanged() {
      let selectedDiscountObject;
      if (this.selectedDiscount == "0" || this.selectedDiscount == 0) {
        selectedDiscountObject = {
          discountPercent: 0,
        };
      } else {
        for (const key in this.discounts) {
          if (this.discounts[key].discountID == this.selectedDiscount) {
            selectedDiscountObject = this.discounts[key];
            break;
          }
        }
      }

      this.$refs.quotingTable.applyQuoteDiscount(selectedDiscountObject);
    },
    convertToQuote() {
      this.$refs.quotingTable.convertToQuote();
    },
  },

  created() {
    this.setSelectedDiscount();
    this.getUserDiscounts();
    this.getUserTaxInclusive();
  },
};
</script>

<style>
.bg-gradient-lighter {
  background: linear-gradient(87deg, #e9ecef 0, #e9eaef 100%);
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 4;
  background-color: white;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 4px;
  width: 326px;
}
.loader-nwh {
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  border-top: 2px solid #000;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
</style>
