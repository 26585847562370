<template>
  <cockpit-header class="h-header"></cockpit-header>
  <div class="bg-blue-950 h-content text-gray-500">
    <div class="flex justify-evenly max-h-content mx-1 py-6 h-full">
      <div class="bg-white relative w-5/20 rounded -mt-28">
        <div>
          <sale-details class="p-1"></sale-details>
          <div
            class="
              scrollbar-thin
              blueScroll
              scrollbar-thumb-gray-200 scrollbar-track-white
              overflow-y-scroll
              scrollbar-thumb-rounded
              max-h-leftpage
            "
          >
            <customer-details class="p-1"></customer-details>
            <sale-lines class="p-1"></sale-lines>
          </div>
        </div>
        <SaleFooter class="absolute bottom-0 left-0 w-full"></SaleFooter>
      </div>
      <div class="bg-white w-14/20 rounded -mt-28">
        <RightContent></RightContent>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerDetails from "@/components/LeftSidebar/CustomerDetails/CustomerDetails";
import SaleDetails from "@/components/LeftSidebar/SaleDetails/SaleDetails";
import CockpitHeader from "@/components/Header/CockpitHeader";
import SaleLines from "@/components/LeftSidebar/SaleDetails/SaleLines";
import SaleFooter from "@/components/LeftSidebar/SaleDetails/SaleFooter";
import RightContent from "@/components/Content/RightContent";

import store from "../store/store";

import {mapGetters} from "vuex";

export default {
  name: "CockpitV2",
  components: {
    SaleFooter,
    CustomerDetails,
    SaleDetails,
    CockpitHeader,
    SaleLines,
    RightContent,
  },
  methods: {
    setAppLanguage() {
      console.log("setting up lang");
      this.$i18n.locale = this.language;
    },
  },
  watch: {
    language() {
      if (this.language) {
        this.setAppLanguage();
      }
    },
    paramsInitialized: {
      handler(newValue, oldValue) {
        console.log('newValue', newValue);
        console.log('oldValue', oldValue);
        if (newValue) {
          store.dispatch("setSale").then(() => {
            store.dispatch("setCustomer");
          });
          store.dispatch("setSettings");
        }
      },
      immediate: true,
    }
  },
  setup() {
    // store.dispatch("setSale");
    // store.dispatch("setSettings");
    // store.dispatch("setCustomer");
  },
  computed: {
    ...mapGetters([
      "shopKey",
      "saleID",
      "sale",
      "onlyReceipt",
      "onlyInvoice",
      "language",
      "paramsInitialized"
    ]),
  }
};
</script>

<style scoped>
</style>
