<template>
  <div class="w-full sm:px-1" v-if="!convertingToQuote">
    <div
      class="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto"
    >
      <table class="w-full whitespace-nowrap table-auto">
        <thead>
          <tr
            tabindex="0"
            class="
                focus:outline-none
                h-16
                w-full
                text-sm
                leading-none
                text-gray-800
              "
          >
            <th
              v-for="(theadValue, key) in theadValues"
              :key="key"
              class="font-semibold  p-2 capitalize w-auto mx-2 "
            >
              {{ theadValue }}
            </th>
          </tr>
        </thead>
        <tbody class="w-full" v-if="localSaleLines.length > 0">
          <tr
            v-for="(saleLine, index) in localSaleLines"
            :key="saleLine.saleLineID"
            tabindex="0"
            class="
                focus:outline-none
                h-20
                text-sm
                leading-none
                text-gray-800
                bg-white
                hover:bg-gray-100
                border-b border-t border-gray-100
              "
          >
            <!-- Item Name-->
            <td class="p-2 mx-3">
              <div class="font-medium" v-setText:item="saleLine"></div>
            </td>
            <!-- Original Price-->
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
            >
              <div class="flex justify-center">
                <p>{{ applicationSettings.invoice_currency }}</p>
                <div
                  class="font-medium"
                  v-setText:unitPriceWithoutTax="saleLine"
                ></div>
              </div>
            </td>
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
            >
              <div class="flex justify-center">
                <p>{{ applicationSettings.invoice_currency }}</p>
                <div
                  class="font-medium"
                  v-setText:unitPriceWithTax="saleLine"
                ></div>
              </div>
            </td>
            <!-- Discount -->
            <td class="p-2 mx-3">
              <div class="font-medium">
                <input
                  class=" bg-gradient-lighter w-20 outline-none rounded p-2 mx-3"
                  type="number"
                  min="0"
                  :value="saleLine.discountPercent ?? 0"
                  @keyup="inputChangeEvent($event, index, 'discountPercent')"
                />%
              </div>
            </td>
            <!--Quoted -->
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
            >
              <div class="flex justify-center">
                <p :class="!isTaxInclusive ? 'p-2' : ''">
                  {{ applicationSettings.invoice_currency }}
                </p>
                <div
                  v-if="isTaxInclusive"
                  class="font-medium"
                  v-setText:quotedPriceWithoutTax="saleLine"
                ></div>
                <div v-else class="font-medium">
                  <input
                    class=" bg-gradient-lighter w-max outline-none rounded p-2 mx-3"
                    type="number"
                    min="0"
                    v-setText:quotedPriceWithoutTax="saleLine"
                    @keyup="
                      inputChangeEvent($event, index, 'quotedPriceWithoutTax')
                    "
                  />
                </div>
              </div>
            </td>
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
            >
              <div class="flex justify-center">
                <p :class="isTaxInclusive ? 'p-2' : ''">
                  {{ applicationSettings.invoice_currency }}
                </p>
                <div
                  v-if="!isTaxInclusive"
                  class="font-medium"
                  v-setText:quotedPriceWithTax="saleLine"
                ></div>
                <div v-else class="font-medium">
                  <input
                    class=" bg-gradient-lighter w-max outline-none rounded p-2 mx-3"
                    type="number"
                    min="0"
                    v-setText:quotedPriceWithTax="saleLine"
                    @keyup="
                      inputChangeEvent($event, index, 'quotedPriceWithTax')
                    "
                  />
                </div>
              </div>
            </td>
            <!-- quantity-->
            <td class="p-2 mx-3">
              <div class="font-medium">
                <input
                  class=" bg-gradient-lighter w-20 outline-none rounded p-2
                mx-3"
                  type="number"
                  step="1"
                  :value="saleLine.quotedItemQty ?? saleLine.unitQuantity"
                  @keyup="inputChangeEvent($event, index, 'quantity')"
                />
              </div>
            </td>
            <!--Item total price subtotal -->
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
            >
              <div class="flex justify-center">
                <p>{{ applicationSettings.invoice_currency }}</p>
                <div
                  class="font-medium"
                  v-setText:subtotalPriceWithoutTax="saleLine"
                ></div>
              </div>
            </td>
            <td
              class="p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
            >
              <div class="flex justify-center">
                <p>{{ applicationSettings.invoice_currency }}</p>
                <div
                  class="font-medium"
                  v-setText:subtotalPriceWithTax="saleLine"
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <div class=" w-20 h-20 ">No Item In this Sale</div>
        </tbody>
        <!-- Footer -->
        <tfoot class="w-full" v-if="localSaleLines.length > 0">
          <tr>
            <td class="p-2 mx-3"></td>

            <td
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
              class="text-right p-2 mx-3"
            >
              Original Price<br />{{ applicationSettings.invoice_currency
              }}<strong class="totalOriginalPriceWithoutTax">{{
                totalOriginalPriceWithoutTax
              }}</strong>
              without tax
            </td>

            <td
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
              class="text-right p-2 mx-3"
            >
              Original Price<br />{{ applicationSettings.invoice_currency
              }}<strong class="totalOriginalPriceWithTax">{{
                totalOriginalPriceWithTax
              }}</strong>
              with tax
            </td>

            <td class="text-right p-2 mx-3"></td>

            <td
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
              class="text-right p-2 mx-3"
            >
              Quoted Price<br />{{ applicationSettings.invoice_currency
              }}<strong class="totalQuotedPriceWithoutTax">{{
                totalQuotedPriceWithoutTax
              }}</strong>
              without tax
            </td>

            <td
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
              class="text-right p-2 mx-3"
            >
              Quoted Price<br />{{ applicationSettings.invoice_currency
              }}<strong class="totalQuotedPriceWithTax">{{
                totalQuotedPriceWithTax
              }}</strong>
              with tax
            </td>

            <td class="text-center p-2 mx-3">
              Quantity<br /><strong id="totalQty">{{ numberOfItems }}</strong>
              item(s)
            </td>

            <!-- summary -->
            <td
              class="text-left p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_exclusive_values == 1"
            >
              Original Price : {{ applicationSettings.invoice_currency
              }}<strong class="mt-1 totalOriginalPriceWithoutTax">{{
                totalOriginalPriceWithoutTax
              }}</strong>
              without tax
              <br />
              Product Discount : {{ applicationSettings.invoice_currency
              }}<strong class="productDiscountWithoutTax mt-1">{{
                productDiscountTaxExclusive
              }}</strong>
              <br />
              Sub Total : {{ applicationSettings.invoice_currency
              }}<strong class="totalQuotedPriceWithoutTax mt-1">{{
                totalQuotedPriceWithoutTax
              }}</strong>
              without tax
              <br />
              Customer Discount : {{ applicationSettings.invoice_currency
              }}<strong class="mt-1" id="discountAmountWithoutTax">{{
                customerDiscountWithoutTax
              }}</strong>
              <br />
              Total : {{ applicationSettings.invoice_currency }}
              <strong id="totalCostWithDiscountWithoutTax" class="mt-1">{{
                totalCostWithoutTaxAfterDiscount
              }}</strong>
              after Discount
            </td>

            <td
              class="text-left p-2 mx-3"
              v-if="applicationSettings.quotes_show_tax_inclusive_values == 1"
            >
              Original Price : {{ applicationSettings.invoice_currency
              }}<strong class="mt-1 totalOriginalPriceWithTax">{{
                totalOriginalPriceWithTax
              }}</strong>
              with tax
              <br />
              Product Discount : {{ applicationSettings.invoice_currency
              }}<strong class="productDiscountWithTax mt-1">{{
                productDiscountTaxInclusive
              }}</strong>
              <br />
              Sub Total : {{ applicationSettings.invoice_currency
              }}<strong class="totalQuotedPriceWithTax mt-1">{{
                totalQuotedPriceWithTax
              }}</strong>
              with tax
              <br />
              Customer Discount : {{ applicationSettings.invoice_currency
              }}<strong class="mt-1" id="discountAmountWithTax">{{
                customerDiscountWithTax
              }}</strong>
              <br />
              Total : {{ applicationSettings.invoice_currency }}
              <strong id="totalCostWithDiscountWithTax" class="mt-1">{{
                totalCostWithTaxAfterDiscount
              }}</strong>
              after Discount
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div v-else class="flex flex-col justify-center">
    <div class="flex justify-center">
      <div class="loader-nwh w-20 h-20 "></div>
    </div>
    <div class="text-lg font-bold">
      Creating the Quote
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { inject } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "QuotingTable",
  setup() {
    let quotingNote = inject("quotingNote");
    return {
      quotingNote,
    };
  },
  data() {
    return {
      localSaleLines: [],
      theadValues: { 0: "item", 3: "discount", 6: "quantity" },
      numberOfItems: 0,
      totalOriginalPriceWithTax: 0,
      totalOriginalPriceWithoutTax: 0,
      totalQuotedPriceWithTax: 0,
      totalQuotedPriceWithoutTax: 0,
      customerDiscountWithoutTax: 0,
      customerDiscountWithTax: 0,
      totalCostWithTaxAfterDiscount: 0,
      totalCostWithoutTaxAfterDiscount: 0,
      selectedDiscount: { discountPercent: 0 },
      convertingToQuote: false,
    };
  },
  props: {
    isTaxInclusive: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([
      "shopKey",
      "sale",
      "applicationSettings",
      "saleData",
      "sale",
    ]),
    ...mapState(["saleLines"]),
    productDiscountTaxExclusive() {
      return (
        this.totalOriginalPriceWithoutTax - this.totalQuotedPriceWithoutTax
      ).toFixed(2);
    },
    productDiscountTaxInclusive() {
      return (
        this.totalOriginalPriceWithTax - this.totalQuotedPriceWithTax
      ).toFixed(2);
    },
  },
  watch: {
    //Will only be called initially , when state changes for this data(if state is not filled initially)
    saleLines() {
      this.processSaleLineData();
      this.processDiscountOnItems();
    },
  },
  methods: {
    setInitialData() {
      if (this.saleLines.length > 0) {
        //Will only be called initially , when saleLines is present in state
        this.processSaleLineData();
        this.processDiscountOnItems();
      }

      //set theadValues (columnName ) (here key will be order of display of thead)
      if (this.applicationSettings.quotes_show_tax_exclusive_values == 1) {
        //Ogprice
        this.theadValues[1] = "original price excl.tax";

        //QuotedPrice
        this.theadValues[4] = "quoted price excl.tax";

        //subtotal
        this.theadValues[7] = "sub Total excl.tax";
      }

      if (this.applicationSettings.quotes_show_tax_inclusive_values == 1) {
        //Ogprice
        this.theadValues[2] = "original price incl.tax";

        //QuotedPrice
        this.theadValues[5] = "quoted price incl.tax";

        //subtotal
        this.theadValues[8] = "sub Total incl.tax";
      }
    },
    processSaleLineData() {
      //Set value of localSaleLines
      this.localSaleLines = this.saleLines;
      this.calculateSaleLineData();
      this.processDiscountOnItems();
    },
    calculateSaleLineData() {
      //Calculate Total Value SaleLine Fields
      let totalQuotedPriceWithTax = 0;
      let totalQuotedPriceWithoutTax = 0;
      let totalOriginalPriceWithTax = 0;
      let totalOriginalPriceWithoutTax = 0;
      let numberOfItems = 0;

      this.localSaleLines.forEach((saleLine) => {
        let quotedPriceWithTax = 0;
        let quotedPriceWithoutTax = 0;

        let itemQty = parseInt(saleLine.unitQuantity);
        let quotedItemQty = itemQty;

        if (saleLine["quotedItemQty"] !== undefined) {
          quotedItemQty = saleLine["quotedItemQty"];
        } else {
          saleLine["quotedItemQty"] = quotedItemQty;
        }

        //Convert qty to integer
        quotedItemQty = parseInt(quotedItemQty);

        let unitPriceWithoutTax = parseFloat(saleLine.calcSubtotal / itemQty);
        unitPriceWithoutTax = !isNaN(unitPriceWithoutTax)
          ? unitPriceWithoutTax
          : 0;
        let unitPriceWithTax = parseFloat(saleLine.unitPrice);

        //update total original price
        totalOriginalPriceWithoutTax += unitPriceWithoutTax * quotedItemQty;
        totalOriginalPriceWithTax += unitPriceWithTax * quotedItemQty;
        //update total quote price

        //Set property in object for quoted price with tax;
        if (saleLine["quotedPriceWithTax"] !== undefined) {
          quotedPriceWithTax = saleLine["quotedPriceWithTax"];
        } else {
          quotedPriceWithTax = unitPriceWithTax;
          saleLine["quotedPriceWithTax"] = quotedPriceWithTax;
        }

        if (saleLine["quotedPriceWithoutTax"] !== undefined) {
          quotedPriceWithoutTax = saleLine["quotedPriceWithoutTax"];
        } else {
          quotedPriceWithoutTax = unitPriceWithoutTax;
          saleLine["quotedPriceWithoutTax"] = quotedPriceWithoutTax;
        }

        //Apply discount too from discount input if applicable
        if (saleLine["discountPercent"] !== undefined) {
          quotedPriceWithoutTax = (
            ((100 - saleLine["discountPercent"]) * unitPriceWithoutTax) /
            100
          ).toFixed(2);
          quotedPriceWithTax = (
            ((100 - saleLine["discountPercent"]) * unitPriceWithTax) /
            100
          ).toFixed(2);
          saleLine["quotedPriceWithoutTax"] = quotedPriceWithoutTax;
          saleLine["quotedPriceWithTax"] = quotedPriceWithTax;
        } else {
          //if empty sale Line we push discount percent accordingly
          if (this.isTaxInclusive) {
            saleLine["discountPercent"] = (
              ((quotedPriceWithTax - unitPriceWithTax) * 100) /
              unitPriceWithTax
            ).toFixed(2);
          } else {
            saleLine["discountPercent"] = (
              ((quotedPriceWithoutTax - unitPriceWithoutTax) * 100) /
              unitPriceWithTax
            ).toFixed(2);
          }
        }

        //Multiply with itemqty for total quoted Price without tax
        quotedPriceWithoutTax *= quotedItemQty;

        //Multiply with itemqty for total quoted Price with tax
        quotedPriceWithTax *= quotedItemQty;

        //update total quote price
        totalQuotedPriceWithTax += parseFloat(quotedPriceWithTax);
        totalQuotedPriceWithoutTax += parseFloat(quotedPriceWithoutTax);
        numberOfItems += quotedItemQty;
      });

      //set Values
      this.totalQuotedPriceWithTax = totalQuotedPriceWithTax;
      this.totalQuotedPriceWithoutTax = totalQuotedPriceWithoutTax;
      this.totalOriginalPriceWithTax = totalOriginalPriceWithTax;
      this.totalOriginalPriceWithoutTax = totalOriginalPriceWithoutTax;
      this.numberOfItems = numberOfItems;
    },

    applyQuoteDiscount(discount) {
      this.selectedDiscount = discount;
      this.processDiscountOnItems();
    },

    processDiscountOnItems() {
      const discount = this.selectedDiscount;
      if (!discount) return;
      const discountPercent = parseFloat(discount.discountPercent);

      //Update Customer Discount with and without Tax
      this.customerDiscountWithoutTax = (
        this.totalQuotedPriceWithoutTax * discountPercent
      ).toFixed(2);
      this.customerDiscountWithTax = (
        this.totalQuotedPriceWithTax * discountPercent
      ).toFixed(2);

      //Update Total Cost After Customer Discount with and without Tax
      this.totalCostWithTaxAfterDiscount = (
        this.totalQuotedPriceWithTax - this.customerDiscountWithTax
      ).toFixed(2);
      this.totalCostWithoutTaxAfterDiscount = (
        this.totalQuotedPriceWithoutTax - this.customerDiscountWithoutTax
      ).toFixed(2);
    },
    getTotalOriginalAndQuotedPrice() {
      this.calculateSaleLineData();
      this.processDiscountOnItems();
    },
    inputChangeEvent(event, saleLineObjectKey, type) {
      const eventValue = event.target.value;
      switch (type) {
        case "quantity":
          this.localSaleLines[saleLineObjectKey].quotedItemQty =
            eventValue == "" || eventValue == null ? 0 : eventValue;
          break;
        case "discountPercent":
          this.localSaleLines[saleLineObjectKey].discountPercent = eventValue;
          break;
        case "quotedPriceWithTax":
          this.localSaleLines[
            saleLineObjectKey
          ].quotedPriceWithTax = eventValue;
          this.localSaleLines[saleLineObjectKey].discountPercent = undefined;
          break;
        case "quotedPriceWithoutTax":
          this.localSaleLines[
            saleLineObjectKey
          ].quotedPriceWithTax = eventValue;
          this.localSaleLines[saleLineObjectKey].discountPercent = undefined;
          break;
      }
      this.getTotalOriginalAndQuotedPrice();
    },
    convertToQuote() {
      if (!this.saleData.accountID) {
        Swal.fire({
          title: "Sale Data Not Loaded for shop",
          text: "Please try again after sometime",
          icon: "info",
        });
        return;
      }
      this.convertingToQuote = true;
      axios
        .post("/api/convertToQuote", {
          saleData: this.saleData,
          sale: this.sale,
          note: this.quotingNote,
          shopKey: this.shopKey,
          switch: 1,
          saleLines: this.localSaleLines,
          discount: this.selectedDiscount,
          isTaxInclusive: this.isTaxInclusive ? 1 : 0,
        })
        .then(function(response) {
          Swal.fire({
            title: "Successfuly converted to quote",
            text: "You can check it at the MDware dashboard",
            icon: "success",
            confirmButtonText: "Go back to Lightspeed",
            confirmButtonColor: "#2c3b4f",
          }).then((result) => {
            if (result.value) {
              return window.location.replace(response.data.urlLightspeed);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
          let errorText = "";
          if (error.response.status == 500) {
            errorText = "we are facing some issues ! please try again later";
          } else {
            errorText = error.response.data.message;
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorText,
          });
        })
        .finally(() => {
          this.convertingToQuote = false;
        });
    },
  },
  directives: {
    setText(el, binding) {
      let text = "";
      const quotedItemQty =
        binding.value.quotedItemQty ?? binding.value.unitQuantity;
      switch (binding.arg) {
        case "item":
          text = binding.value.Item.description
            ? binding.value.Item.description
            : binding.value.Note.note;
          break;
        case "unitPriceWithoutTax":
          text = isNaN(
            parseFloat(binding.value.calcSubtotal / binding.value.unitQuantity)
          )
            ? 0
            : binding.value.calcSubtotal / binding.value.unitQuantity;
          break;
        case "unitPriceWithTax":
          text = binding.value.unitPrice;
          break;
        case "quotedPriceWithTax":
          //check if sale has quotedPrice Present else calculate unitPrice
          text = binding.value.quotedPriceWithTax ?? binding.value.unitPrice;
          break;
        case "quotedPriceWithoutTax":
          text =
            binding.value.quotedPriceWithoutTax ??
            (isNaN(
              parseFloat(
                binding.value.calcSubtotal / binding.value.unitQuantity
              )
            )
              ? 0
              : binding.value.calcSubtotal / binding.value.unitQuantity);
          break;
        case "subtotalPriceWithoutTax": {
          let quotedPriceWithoutTax =
            binding.value.quotedPriceWithoutTax ??
            (isNaN(
              parseFloat(
                binding.value.calcSubtotal / binding.value.unitQuantity
              )
            )
              ? 0
              : binding.value.calcSubtotal / binding.value.unitQuantity);

          text = (quotedPriceWithoutTax * quotedItemQty).toFixed(2);
          break;
        }
        case "subtotalPriceWithTax": {
          let quotedPriceWithTax =
            binding.value.quotedPriceWithTax ?? binding.value.unitPrice;
          text = (quotedPriceWithTax * quotedItemQty).toFixed(2);
          break;
        }
      }
      if (el.tagName == "INPUT") {
        el.value = parseFloat(text);
      } else {
        el.innerHTML = text;
      }
    },
  },
  //   },
  mounted() {
    this.setInitialData();
  },
};
</script>

<style scoped></style>
