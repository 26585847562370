<template>
  <div id="header" class="-mt-4">
    <div class="w-19/20 mx-auto flex justify-between">
      <div class="flex justify-start items-center">
        <img
          class="h-2/5 pr-3"
          src="https://mdware.org/wp-content/uploads/2019/10/MDware160X2.png"
          alt="MDware logo"
        />
        <p class="text-white font-extralight text-4xl">|</p>
        <p class="mx-3 text-xl text-white font-medium uppercase">
          {{ $t("sale") }} {{ $t("cockpit") }}
        </p>
      </div>
      <div
        class="bg-white flex my-auto items-center justify-between px-2 py-5 h-8 rounded cursor-pointer"
        @click="goToReturnUrl(returnUrl)"
      >
        <box-icon
          class="pr-2"
          type="solid"
          color="#17A2B8"
          name="chevron-left"
        ></box-icon>
        <a class="text-blue-600 font-semibold text-center">{{
          $t("backToSale")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CockpitHeader",
  setup() {
    function goToReturnUrl(url) {
      window.location.href = url;
    }

    return {
      goToReturnUrl,
    };
  },

  computed: {
    ...mapGetters(["returnUrl", "shopKey"]),
  },
};
</script>

<style scoped>
#header {
  background-color: #17a2b8;
  padding-bottom: 8rem;
}
</style>
