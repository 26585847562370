<template>
    <teleport to="body">
        <div
            ref="modal-backdrop"
            class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
        >
            <div class="flex items-start justify-center pt-24 text-center">
                <div
                    class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-2 w-5/6 max-w-2xl"
                    style="height: 30rem"
                    role="dialog"
                    ref="modal"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div class="w-full h-full">
                        <iframe
                            :src="ccvPayUrl"
                            class="w-full h-full"
                            v-show="!hasError && frameIsLoaded && ccvPayUrl !== ''"
                            @load="handleFrameLoad"
                            @error="handleFrameError"
                        />
                        <div
                            v-if="hasError"
                            class="h-full w-full flex items-center justify-center"
                        >
                            <div class="text-center">
                                <h3>Something went wrong with the payment</h3>
                                <Button @click="handleTryAgainEvent">Try again</Button>
                            </div>
                        </div>

                        <div v-if="ccvPayUrl == ''" class="h-full w-full flex items-center justify-center">
                            <div class="text-center">
                                <h2 class="text-4xl">Please wait</h2>

                                <h4 class="text-3xl">We're processing your payment</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {onMounted, ref} from "@vue/runtime-core";
import {mapGetters} from "vuex";
import store from "@/store/store";
import get from 'lodash/get';
//import axios from 'axios';

export default {
    computed: {
        ...mapGetters(["ccvPayUrl", "ccvPayment"]),
    },
    setup(props) {
        window.addEventListener(
       "message",
            (event) => {
                /**
                 * Do we trust the sender of this message?
                 * (might be different from what we originally opened)
                 */
                if (event.origin !== window.location.origin) return;

                console.log(event);
                console.log("event got inside");
                handleMessageEvent(event);
            },
            false
        );

        let frameIsLoaded = ref(false);
        let hasError = ref(false);

        const handleFrameLoad = () => {
            frameIsLoaded.value = true;
        };
        const handleError = () => {
            hasError.value = true;
            //this.$emit('error', error)
        };
        const handleFrameError = () => {
            handleError();
        };
        const handleCompleted = (data) => {
            //this.$emit('completion', data)
            // this.$modal.hide('ccvModal')

            //Newly uncommented
            store.commit("setCcvAmount", data.data.amount);
            store.commit("setCcvStatus", "Success");
            store.commit("setCanFinalize", true);
            store.commit("showCcvModal", false);
            store.dispatch("ccvPaymentSuccessful", data);
        };
        const handleCancelation = () => {
            //this.$emit('cancelled', data)
            //this.$modal.hide('ccvModal')
            store.commit("showCcvModal", false);
            store.commit("setErrorMessage", {
                name: "ccvPayment",
                message: "CCV Payment failed",
            });
            store.commit("setCcvStatus", "Failed");
            store.commit("setCanFinalize", false);
        };
        const handlePaymentPending = () => {
            //this.$emit('pending', data)
            //this.$modal.hide('ccvModal')
            store.commit("showCcvModal", false);
        };
        const handleMessageEvent = ({data}) => {
            //this.$store.commit('updateCcvPaying', false)
            const orderDetailsUrl = store.getters.ccvPayUrl;
            const returnedData = {...data, orderDetailsUrl};
            //console.log(returnedData)

            switch (get(data, 'data.status', get(data, 'status', ''))) {
                case "success":
                    handleCompleted(returnedData);
                    break;
                case "pending":
                    handlePaymentPending(returnedData);
                    break;
                case "cancelled":
                    handleCancelation(returnedData);
                    break;
                case "failed":
                    handleCancelation(returnedData);
                    break;
                default:
                    break;
            }

            reset();
        };
        const reset = () => {
            frameIsLoaded.value = false;
            hasError.value = false;
        };
        const handleTryAgainEvent = () => {
            //.commit('updateCcvPaying', true)
            // this.$modal.hide('ccvModal')
            loadPayment();
        };

        const loadPayment = () => {
            store.dispatch("getCcvPayUrl", props.amount);
        };

        onMounted(() => {
            /** Since component should be controlled with `v-if` prop modal is opened on render */
            //this.$modal.show('ccvModal')
        });
        return {
            handleError,
            handleFrameLoad,
            handleFrameError,
            handleTryAgainEvent,
            frameIsLoaded,
            hasError,
        };
    },
};
</script>

<style></style>
