import { createWebHistory, createRouter } from "vue-router";
import Start from "../Start.vue";
import CCVProcessor from "../components/Payments/CCVProcessor.vue";

const routes = [
  {
    path: "/cockpit",
    name: "Start",
    component: Start,
  },
  {
    path: "/ccv/:reference",
    name: "CCV",
    component: CCVProcessor,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;