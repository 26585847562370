import { round } from "../helpers/round";

export default {
  applicationSettings: (state) => state.applicationSettings,
  ccvPayUrl: (state) => state.ccvPayUrl,
  customer: (state) => state.customer,
  isResto: (state) => state.isResto,
  language: (state) => state.applicationSettings.account_language,
  paramsInitialized: state => state.paramsInitialized,
  sale: (state) => state.sale,
  shopKey: (state) => state.shopKey,
  type: (state) => state.type,
  accountID: (state) => state.accountID,
  systemUserID: (state) => state.systemUserID,
  employeeID: (state) => state.employeeID,
  saleID: (state) => state.saleID,
  customerID: (state) => state.customerID,
  shopID: (state) => state.shopID,
  registerID: (state) => state.registerID,
  returnUrl: (state) => state.returnUrl,
  saleData: (state) => state.saleData,
  invoicingEnabled: (state) => state.applicationSettings.invoicing_v2_enabled,
  invoiceCurrency: (state) => state.applicationSettings.invoice_currency,
  saleLines: (state) => state.saleLines,
  paymentMethods: (state) => state.paymentMethods,
  terminalPayments: (state) => state.terminalPayments,
  balance: (state) => round(state.balance),
  loyaltyEvents: (state) => state.loyaltyEvents,
  hasWallee: (state) => state.hasWallee,
  shop: (state) => state.shop,
  showFinalizeButtons: (state) => state.showFinalizeButtons,
  giftCardData: (state) => state.giftCardData,
  user: (state) => state.user,
  integrationRetailRoundedPaymentIds: (state) => [
    state.applicationSettings.integration_retail_rounded_payment_1_id,
    state.applicationSettings.integration_retail_rounded_payment_2_id,
    state.applicationSettings.integration_retail_rounded_payment_3_id,
    state.applicationSettings.integration_retail_rounded_payment_4_id,
  ],
  integrationRetailOpenPaymentIds: (state) => [
    state.applicationSettings.integration_retail_open_payment_1_id,
    state.applicationSettings.integration_retail_open_payment_2_id,
    state.applicationSettings.integration_retail_open_payment_3_id,
    state.applicationSettings.integration_retail_open_payment_4_id,
  ],
  note: (state) => state.note,
  urlParams: (state) => state.urlParams,
  roundedAmount: (state) => state.roundedAmount,
  change: (state) => round(state.change),
  ccvPayment: (state) => state.ccvPayment,
  showCcvModal: state => state.ccvPayment.showModal,
  email: (state) => state.email,
  email2: (state) => state.email2,
  sendMail: (state) => state.sendMail,
  canFinalize: (state) => state.canFinalize,
  isCreditNota: (state) => state.sale["calcTotal"] < 0.0,
  onlyReceipt: (state) =>
    !hasLoyaltyQuotingPanel(state) && !hasInvoicing(state),
  onlyInvoice: (state) => !hasLoyaltyQuotingPanel(state) && hasInvoicing(state),
  hasRightPanelInvoice: (state) =>
    hasLoyaltyQuotingPanel(state) && hasInvoicing(state),
  hasRightPanelReceipt: (state) =>
    hasLoyaltyQuotingPanel(state) && !hasInvoicing(state),
  loyaltyAdded: (state) => state.loyaltyAdded,
  loyaltyUsed: (state) => state.loyaltyUsed,
  isLoyaltyRedeemLoading: (state) => state.isLoyaltyRedeemLoading,
  isLoyaltyAddedLoading: (state) => state.isLoyaltyAddedLoading,
};

const hasLoyaltyQuotingPanel = (state) => {
  return (
    state.applicationSettings.retail_quoting_enabled ||
    (state.applicationSettings.loyalty_enabled && state.customer.isValidLoyalty)
  );
};

const hasInvoicing = (state) => {
  return !state.customer.errorMessage && state.customer.isValidRetail;
};
