<template>
  <div class="w-full py-2">
    <PaymentMethods></PaymentMethods>

    <!-- <payment-processor class="flex justify-between mt-2 rounded-lg items-center bg-gray-50"
                v-bind="$attrs"
                :max="maxAmount"
                :type="paymentMethod.name"
                :currency="currency"
                :name="paymentMethod.paymentTypeID"
                :payment-type-id="paymentMethod.paymentTypeID"
                :value="paymentMethod.value"
                :original-amount="max"
                :cash-rounding-enabled="cashRoundingEnabled(paymentMethod.paymentTypeID)"
                :adjustment-value="adjustmentValue"
                :max-amount="maxAmount"
                :sale-complete="isSaleComplete"
                :remaining="remaining(paymentMethod.paymentTypeID)"
                :amount-entered="amountEntered"
                :amount="getAmount(paymentMethod.paymentTypeID)"
                :terminal-payments="getTerminalPayments(paymentMethod.name)"
                v-for="paymentMethod in validPaymentMethods"
                :key="paymentMethod.paymentTypeID"
                :credit-balance="paymentMethod.name.toLowerCase() === 'credit account' ? creditAccountAmount: -1"
                :rounded-enabled="roundedEnabled(paymentMethod.paymentTypeID)"
                :enabled-processors="enabledProcessors"
                :payment-type-i-d="paymentMethod.paymentTypeID"
                :terminal-payment-type-ids="terminalPaymentTypeIds"
        >
        </payment-processor> -->

    <!-- giftcard Wrapper here -->
    <!-- <gift-card
                :currency="currency"
                @update="updateAmount(giftCardPaymentId, $event)"
                @charged="amountCharged(giftCardPaymentId)"
                :max-amount="parseFloat(max)"
                :remaining="remaining()"
                class="bg-blue-100 mx-auto p-2 border-2 border-gray-300 rounded"
        /> -->
    <input type="hidden" name="roundedAmount" :value="adjustmentValue" />
  </div>
</template>
<style >
.bottom-padding {
  padding-bottom: 10px;
  padding-top: 10px;
}
.style-alternate-child > div:nth-of-type(odd) {
  width: 100% !important;
  background-color: #efeded;
}
</style>
<script>
//import PaymentProcessor from "@/components/Content/Payment/PaymentProcessor/PaymentProcessor";
//import GiftCard from "@/components/Content/Payment/PaymentProcessor/GiftCard";
import _ from "lodash";
import EventBus from "@/components/Content/Payment/eventBus";
import { mapGetters } from "vuex";
import store from "@/store/store";
import PaymentMethods from "../PaymentMethods.vue";

export default {
  inheritAttrs: false,
  components: {
    //PaymentProcessor,
    //GiftCard,
    PaymentMethods,
  },
  data() {
    return {
      adjustmentValue: 0,
      giftCardPaymentId: "5",
    };
  },
  setup() {
    let amounts = {};
    return {
      amounts,
    };
  },
  mounted() {
    EventBus.on("update", ($event) => {
      this.updateAmount($event.paymentTypeID, $event);
    });
    EventBus.on("charged", ($event) => {
      this.amountCharged($event.paymentTypeID);
    });
  },
  computed: {
    ...mapGetters(["balance", "invoiceCurrency"]),
    validPaymentMethods() {
      return this.paymentMethods.filter((paymentMethod) => {
        let methods = paymentMethod.paymentTypeID !== this.giftCardPaymentId;
        if (!this.validRetailCustomer) {
          methods =
            methods &&
            !this.openPaymentIds.includes(
              parseInt(paymentMethod.paymentTypeID)
            );
        }
        return methods;
      });
      //return this.paymentMethods;
    },
    remaining() {
      return (paymentTypeID) => {
        let remainingAmount =
          this.maxAmount -
          _.sumBy(Object.values(this.amounts), (amount) => {
            return (
              amount.amount -
              ((amount.isRounding || amount.givesChange) &&
              amount.amount > Math.abs(this.maxAmount)
                ? amount.amount - this.maxAmount
                : 0)
            );
          });
        if (paymentTypeID) {
          remainingAmount +=
            this.amounts[paymentTypeID].amount -
            (this.amounts[paymentTypeID].isRounding &&
            this.amounts[paymentTypeID].amount > Math.abs(this.maxAmount)
              ? this.amounts[paymentTypeID].amount - this.maxAmount
              : 0);
        }
        remainingAmount = parseFloat(remainingAmount.toFixed(2));
        //let balance = (remainingAmount > 0) ? remainingAmount.toFixed(2) : 0.00;
        /**
         * Updating the balance in the top-right section of the cockpit
         * using JavaScript element selector. In future if that part is
         * converted to vue component we should use the events instead
         * of updating the element using css selector.
         *
         * @example this.$eventBus('emit', remainingAmount);
         */
        //document.getElementById('remainingAmount').innerHTML = "<b>" + this.currency + " " + balance + "</b>";

        return remainingAmount;
      };
    },

    isSaleComplete() {
      return (
        _.reduce(
          Object.values(this.amounts),
          (carry, data) => {
            if (data.charged) {
              carry += data.amount;
            }

            return carry;
          },
          0
        ) === this.max
      );
    },

    amountEntered() {
      return _.reduce(
        Object.values(this.amounts),
        (carry, data) => {
          return carry + data.amount;
        },
        0
      );
    },

    cashRoundingEnabled() {
      return (methodId) => {
        return (
          this.cashPaymentMethodId > -1 &&
          parseInt(this.cashPaymentMethodId) === parseInt(methodId)
        );
      };
    },

    getAmount() {
      return (paymentId) => this.amounts[paymentId].amount;
    },

    maxAmount() {
      return parseFloat(Number(this.max + this.adjustmentValue).toFixed(2));
    },

    roundedEnabled() {
      return (paymentId) => {
        return this.roundedPaymentIds.indexOf(parseInt(paymentId)) > -1;
      };
    },
  },

  props: {
    cashPaymentMethodId: {
      default: -1,
    },
    validRetailCustomer: {
      default: 0,
    },
    lessCustomerInfo: {
      default: 0,
    },
    customer: {
      type: Object,
      default() {
        return { email: "", credit_account_id: 0 };
      },
    },
    currency: {
      type: String,
      default: "",
    },

    enabledProcessors: {
      type: Array,
      default() {
        return [];
      },
    },

    max: {
      default: 0,
    },

    paymentMethods: {
      default() {
        return [];
      },
    },
    creditAccountAmount: {
      default: -1,
    },
    roundedPaymentIds: {
      default() {
        return [];
      },
    },
    openPaymentIds: {
      default() {
        return [];
      },
    },

    terminalPayments: {
      default() {
        return [];
      },
    },

    terminalPaymentTypeIds: {
      default() {
        return {};
      },
    },
  },

  methods: {
    updateAmount(paymentId, data) {
      //let amounts = { ...this.amounts };

      let amount = {
        amount: parseFloat(Number(data.value).toFixed(2)),
        charged: false,
        givesChange: data.givesChange,
      };
      this.adjustmentValue = parseFloat(
        Number(data.adjustmentValue).toFixed(2)
      );
      if (data.isRounding) {
        amount.isRounding = true;
      }
      this.amounts[paymentId] = amount;
      store.commit("subtractAmounts", this.amounts);
    },

    getTerminalPayments(name) {
      return _.filter(this.terminalPayments, {
        payment_method_name: name.toLowerCase(),
      });
    },

    amountCharged(paymentId) {
      this.amounts[paymentId] = { ...this.amounts[paymentId], charged: true };
    },
  },

  created() {
    _.forIn(this.paymentMethods, (paymentMethod) => {
      this.amounts[paymentMethod.paymentTypeID] = {
        amount: 0,
        charged: false,
      };
    });

    this.amounts[this.giftCardPaymentId] = {
      amount: 0,
      charged: false,
    };
  },

  watch: {
    adjustmentValue(newValue) {
      let element = document.getElementById("cash-rounding-product");

      if (element) {
        element.remove();
      }
      if (newValue) {
        let table = document
          .getElementById("cockpitProductsTable")
          .querySelector("tbody");

        let element = table.querySelector("tr").cloneNode(true);

        element.id = "cash-rounding-product";

        for (let i = 0; i < element.children.length; i++) {
          if (i === 0) {
            element.children[i].innerText = "Cash Rounding";
          } else if (i === 1) {
            element.children[i].innerText = "";
          }

          element.children[i].innerText = element.children[i].innerText.replace(
            /-?\d+.\d+/,
            newValue
          );
        }

        table.appendChild(element);
      }

      let productTableTotal = document.getElementById("productTableTotal");
      let totalAmount = document.getElementById("totalMoney");
      let productTableTotalGiftCard = document.getElementById(
        "productTableTotalGiftCard"
      );
      let newAmount = Number(this.max + newValue).toFixed(2);
      productTableTotal.innerText = this.currency + " " + newAmount;
      totalAmount.innerText = this.currency + " " + newAmount;

      if (productTableTotalGiftCard) {
        productTableTotalGiftCard.innerText =
          productTableTotalGiftCard.innerText.replace(/-?\d+\.\d+/, newAmount);
      }
    },
  },
};
</script>
<style scoped>
.remaining-balance {
  color: red;
}
</style>