<template>
  <div class="my-3 pb-56">
    <div class="table-fixed text-gray-500 w-11/12 mx-auto">
      <div class="text-left flex justify-between w-full px-2">
        <p class="w-1/12 text-xs">#</p>
        <p class="w-2/3 text-xs">{{ lang["PRODUCT"][language] }}</p>
        <p class="w-3/12 text-xs">{{ $t("total.total") }}</p>
      </div>
      <div
        v-for="saleLine in saleLines"
        :key="saleLine.saleLineID"
        v-bind:class="{
          discount: isDiscount(parseFloat(saleLine.discountValue)),
        }"
      >
        <div
          v-if="isDiscount(parseFloat(saleLine.discountValue))"
          class="border-b-2 py-3 w-full px-2 flex justify-between text-left"
        >
          <p class="w-1/12">{{ saleLine.unitQuantity }}</p>
          <p
            class="w-2/3 pr-3"
            v-if="saleLine.Item && saleLine.Item.description"
          >
            {{ saleLine.Item.description }}
          </p>
          <p v-else class="w-2/3">{{ saleLine.Note.note }}</p>
          <div class="w-3/12 text-right text-blue-600">
            <p>
              {{ applicationSettings.invoice_currency
              }}{{ parseFloat(saleLine.originalPrice).toFixed(2) }}
            </p>
            <p class="text-xs">
              - {{ applicationSettings.invoice_currency
              }}{{ parseFloat(saleLine.discountValue).toFixed(2) }}
            </p>
          </div>
        </div>
        <div
          v-else
          :class="[
            'border-b-2 py-3 w-full px-2 flex text-left',
            saleLine.calcTotal != '0' ? 'justify-between' : 'justify-start',
          ]"
        >
          <p class="w-1/12">{{ saleLine.unitQuantity }}</p>
          <p class="w-2/3" v-if="saleLine.Item && saleLine.Item.description">
            {{ saleLine.Item.description }}
          </p>
          <p v-else class="w-2/3 text-left">{{ saleLine.Note.note }}</p>
          <p class="w-3/12 text-right">
            <!-- v-if="saleLine.calcTotal != '0'"-->
            {{ applicationSettings.invoice_currency }}{{ saleLine.calcTotal }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { onMounted } from "vue";
import store from "@/store/store";
import lang from "../../../assets/lang";

export default {
  name: "SaleLines",
  computed: {
    ...mapGetters(["saleLines", "applicationSettings", "sale", "language"]),
  },
  setup() {
    let isDiscount = (value) => {
      return value > 0.0;
    };
    onMounted(() => {
      store.dispatch("setSaleLines");
    });

    return {
      isDiscount,
      lang,
    };
  },
};
</script>

<style scoped></style>
