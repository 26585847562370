<template>
  <div
    v-if="validCard"
    class="flex justify-between mt-1 bg-green-100 rounded items-center"
  >
    <p class="w-2/3 pl-2">Discount:</p>
    <p class="w-1/3 bg-green-100 cursor-default">
      {{ invoiceCurrency }} {{ validCard.amount.toFixed(2) }}
    </p>
    <div
      class="bg-green-100 p-2 cursor-pointer flex items-center"
      @click="deleteGiftCard(validCard)"
    >
      <box-icon name="x-circle" type="solid" color="#17A2B8"></box-icon>
    </div>
  </div>
  <div
    v-else-if="errorMessage != ''"
    class="flex justify-between mt-1 bg-red-100 rounded items-center"
  >
    <p class="w-2/3 pl-2">{{ errorMessage }}</p>
    <input
      class="w-1/3 bg-red-100"
      v-model="giftCardCode"
      placeholder="Code xxx-xxx"
      type="text"
    />
    <div v-if="loading" class="bg-red-100 p-2 cursor-pointer flex items-center">
      <div class="loader-black"></div>
    </div>
    <div
      v-else
      class="bg-red-100 cursor-pointer flex items-center p-6"
      @click="fetchGiftCard"
    >
      <box-icon name="reset" color="#aa0000"></box-icon>
    </div>
  </div>
  <div v-else class="flex justify-between mt-1 bg-gray-50 rounded items-center">
    <p class="w-2/3 pl-2">Giftcard</p>
    <input
      class="w-1/3 bg-gray-50"
      v-model="giftCardCode"
      type="text"
      @keyup.enter="fetchGiftCard"
      placeholder="Code xxx-xxx"
    />
    <div
      class="bg-blue-100 cursor-pointer flex items-center p-6"
      v-if="loading"
    >
      <div class="loader-black"></div>
    </div>
    <div
      v-else
      class="bg-blue-100 cursor-pointer flex items-center p-6"
      @click="fetchGiftCard"
    >
      <box-icon color="#17A2B8" name="search-alt"></box-icon>
    </div>
  </div>
  <div v-if="giftCard.balance != '0'">
    <div class="p-2 mt-1 rounded bg-blue-100">
      <p>
        Amount remaining giftcard: {{ invoiceCurrency }}
        {{ maxCardAmount(giftCard) }}
      </p>
      <div class="">
        <div>
          <div class="flex justify-start my-2">
            <p>Charge amount: {{ invoiceCurrency }}</p>

            <input
              v-model="giftCard.value"
              type="number"
              placeholder="0.00"
              step="0.01"
              min="0.01"
              :max="maxCardAmount(giftCard)"
              class="pl-1"
            />
          </div>
          <div v-if="giftCardErrorMessage != ''" class="text-sm text-red-500">
            {{ giftCardErrorMessage }}
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <p
            class="
              rounded
              p-3
              bg-blue-800
              text-white
              cursor-pointer
              text-center text-sm
              font-medium
            "
            @click="chargeGiftCard"
          >
            Charge
          </p>
          <p
            class="
              text-blue-800
              rounded
              ml-2
              bg-white
              p-3
              text-center
              cursor-pointer
              text-sm
              font-medium
              shadow
            "
            @click="cancelGiftCard"
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  </div>

  <vue-final-modal v-model="showModal">
    <div class="bg-white mt-10 p-8 w-1/2 rounded-lg mx-auto relative">
      <button
        class="absolute top-0 right-0 mt-2 mr-2"
        @click="showModal = false"
      >
        <box-icon name="x-circle"></box-icon>
      </button>
      <div class="my-3">
        <div class="flex text-left justify-around">
          <p>Giftcard code:</p>
          <p class="font-semibold text-blue-950">{{ giftCardCode }}</p>
        </div>
        <div></div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, ref } from "vue";
import { mapGetters } from "vuex";
import store from "@/store/store";
import axios from "axios";
import { round } from "../../../helpers/round";

export default {
  name: "Giftcard",
  props: ["validCard"],
  setup() {
    let giftCardCode = ref("");
    let giftCard = ref({
      balance: "0",
      value: 0.0,
      code: "",
    });
    let showModal = ref(false);
    let errorMessage = ref("");
    let giftCardErrorMessage = ref("");
    let loading = ref(false);

    const fetchGiftCard = () => {
      if (giftCardCode.value !== "") {
        loading.value = true;
        axios
          .get(`/api/giftcards`, {
            params: {
              shopKey: store.getters.shopKey,
              giftcardCode: giftCardCode.value,
            },
          })
          .then((response) => {
            giftCard.value.balance = response.data.CreditAccount.balance;
            giftCard.value.code = response.data.CreditAccount.code;
            giftCard.value.creditAccountID =
              response.data.CreditAccount.creditAccountID;
            let totalRemainingAmountGiftCard = Math.abs(
              round(response.data.CreditAccount.balance)
            );

            if (totalRemainingAmountGiftCard > round(store.getters.balance)) {
              giftCard.value.value = round(store.getters.balance);
            } else {
              giftCard.value.value = totalRemainingAmountGiftCard;
            }

            loading.value = false;
            giftCardCode.value = "";
          })
          .catch((error) => {
            console.log(error);
            errorMessage.value = error.response.data.message;
            loading.value = false;
            giftCardCode.value = "";
          });
      }
    };

    const chargeGiftCard = () => {
      if (giftCard.value.value <= 0)
        return (giftCardErrorMessage.value = "Amount must be greater than 0");
      if (giftCard.value.value > store.getters.balance)
        return (giftCardErrorMessage.value =
          "Amount can't be greater than remaining balance");
      if (giftCard.value.value > Math.abs(round(giftCard.value.balance)))
        return (giftCardErrorMessage.value =
          "Amount can't be greater than remaining giftcard balance");

      store.commit("addGiftCardData", giftCard.value);
      cancelGiftCard();
    };

    const cancelGiftCard = () => {
      giftCard.value.balance = "0";
      giftCard.value.value = 0;
      giftCard.value.code = "";
      giftCard.value.creditAccountID = "";
    };

    const isValidCard = (code, data) => {
      let index = data
        .map(function (e) {
          return e.code;
        })
        .indexOf(code);
      return index > -1;
    };

    const deleteGiftCard = (giftCard) => {
      store.commit("deleteGiftCard", giftCard.code);
    };

    onMounted(() => {
      giftCardCode.value = "";
    });

    return {
      fetchGiftCard,
      giftCardCode,
      showModal,
      giftCard,
      cancelGiftCard,
      errorMessage,
      giftCardErrorMessage,
      loading,
      isValidCard,
      deleteGiftCard,
      chargeGiftCard,
    };
  },
  methods: {
    maxCardAmount(giftCard) {
      if (store.getters.balance > Math.abs(round(giftCard.balance))) {
        return Math.abs(round(giftCard.balance));
      }
      let card = store.getters.giftCardData.find(
        (element) => element.code == giftCard.code
      );

      if (card) {
        return Math.abs(round(giftCard.balance)) - round(card.amount);
      }
      return Math.abs(round(giftCard.balance));
    },
  },
  computed: {
    ...mapGetters(["giftCardData", "invoiceCurrency", "shopKey"]),
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>