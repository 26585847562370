<template>
  <div class="bg-white h-screen flex items-center justify-center">
    <div class="text-center">
      <h2 class="text-4xl">Hold up</h2>
      <h4 class="text-3xl">We are checking your payment</h4>
    </div>
  </div>
</template>

<script>
import store from "../../store/store.js";
import axios from "axios";
import { useRoute } from "vue-router";
import { onMounted } from 'vue'

export default {
  components: {},
  setup() {
    const route = useRoute();

    onMounted(async () => {
      const reference = route.params.reference;
      console.log(route.params)
      const ccvStatus = await getCcvStatus(reference);

      console.log(ccvStatus);
      let obj = JSON.parse(JSON.stringify(ccvStatus));
      window.parent.postMessage(obj, window.location.origin);
    });

    const getCcvStatus = async (reference) => {
      if (!reference || reference == "") {
        let params = new URLSearchParams(store.getters.ccvPayUrl);
        reference = params["reference"];
      }

      const data = await axios.get(
        `/api/ccvPayments/${reference}`,
      );
      return data;
    };
  },
};
</script>

<style>
</style>

