<template>
  <div
    class="flex justify-around items-center bg-blue-300 rounded-md float-right px-4 max-w-xs py-2 cursor-pointer max-h-9 my-3"
    @click="showLoyaltyEvents = true"
  >
    <p
      class="text-blue-600 text-center text-xs lg:text-sm font-semibold capitalize"
    >
      {{ $t("recentLoyaltyActions") }}
    </p>
    <box-icon
      class="ml-1 max-h-5"
      color="#17A2B8"
      type="solid"
      name="chevron-right"
    ></box-icon>
  </div>

  <vue-final-modal v-model="showLoyaltyEvents">
    <div
      class="bg-white mt-10 p-8 w-5/6 md:max-w-modal rounded-lg mx-auto relative"
    >
      <p class="my-2 text-lg text-left">{{ $t("recentLoyaltyActions") }}</p>
      <button
        class="absolute top-0 right-0 mt-2 mr-2"
        @click="showLoyaltyEvents = false"
      >
        <box-icon name="x-circle"></box-icon>
      </button>
      <div
        class="text-left text-gray-500 w-11/12 mx-auto my-2 flex justify-between"
      >
        <p class="w-3/20 text-sm">DATE</p>
        <p class="w-1/2 text-sm">TYPE</p>
        <p class="w-3/20 text-sm">AMOUNT</p>
        <p class="w-3/20 text-sm">SALE</p>
        <p class="w-3/20 text-sm">N°</p>
      </div>
      <div
        class="my-3 max-h-rightpage overflow-y-auto scrollbar-thin blueScroll scrollbar-thumb-gray-200 scrollbar-track-white scrollbar-thumb-rounded"
      >
        <div class="table-fixed text-gray-500 w-11/12 mx-auto">
          <div
            class="text-left border-b-2 py-3 flex justify-between text-left"
            v-for="loyaltyEvent in loyaltyEvents"
            :key="loyaltyEvent.id"
          >
            <p class="w-3/20">{{ formatDate(loyaltyEvent.created_at) }}</p>
            <p class="w-1/2">{{ loyaltyEvent.loyalty_action }}</p>
            <p v-if="loyaltyEvent.is_points_event" class="w-3/20">
              {{ loyaltyEvent.loyalty_points }}
            </p>
            <p v-else class="w-3/20">
              {{ applicationSettings.invoice_currency
              }}{{ loyaltyEvent.loyalty_amount }}
            </p>
            <p class="w-3/20">
              {{ applicationSettings.invoice_currency
              }}{{ loyaltyEvent.external_sale_total }}
            </p>
            <p class="w-3/20">{{ loyaltyEvent.external_sale_id }}</p>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { ref } from "vue";

export default {
  name: "LoyaltyEventsModal",

  computed: {
    ...mapGetters(["loyaltyEvents", "applicationSettings"]),
  },
  setup() {
    let showLoyaltyEvents = ref(false);

    function formatDate(date) {
      if (date) {
        let splitted = date.split("T")[0].split("-");
        return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
      }
    }
    return {
      showLoyaltyEvents,
      formatDate,
    };
  },
};
</script>

<style scoped></style>
