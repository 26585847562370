export default {
  payment: "Betaling",
  balance: "Balans",
  product: "product",
  loyalty: "loyaliteit",
  detail: "detail | details",
  actions: "gebeurtenissen",
  quoting: "met een citaat van",
  sale: "verkoop",
  cockpit: "cockpit",
  backToSale: "terug naar @:sale",
  customer: "klant",
  amount: "bedrag",
  customerReward: {
    given: "Deze klant kan loyalty krijgen.",
    notGiven: "Deze klant kan geen beloning krijgen.",
  },
  useLoyaltyCredits: "loyaliteitskredieten gebruiken",
  addLoyaltyAmountToCustomer: "loyaliteitsbedrag aan klant toevoegen",
  recentLoyaltyActions: "recente loyalty @:actions",
  item: "item | items",
  max: "max",
  giftcard: "giftcard | giftcards",
  total: {
    incVat: "totaal.incl.VAT",
    excVat: "totaal.excl.VAT ",
    total: "Totaal",
  },
  shopDetailsLoading: "details van de winkel worden nog geladen",
  invoice: "factuur",
  reciept: "ontvangstbewijs",
  byDefault: {
    points: "punt(en) per",
    default: "standaard",
    spentOfThisSale: "besteed aan deze verkoop",
    ofThisSale: "van deze verkoop",
  },
  saleAlreadyCompleted: "@:sale reeds voltooid",
  returnToLightSpeed: "terug naar lightspeed",
  noValidCustomerAttachedToThisSale:
    "Er is geen geldige klant verbonden aan deze verkoop",
};
