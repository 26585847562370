<template>
  <cockpit-header class="h-header"></cockpit-header>

  <div class="bg-blue-950 h-content text-gray-500">
    <div class="flex justify-evenly mx-1">
      <div class="bg-white relative w-5/20 rounded -mt-28">
        <div>
          <!--          <sale-details class="p-1"></sale-details>-->
          <div
            class="scrollbar-thin blueScroll scrollbar-thumb-gray-200 scrollbar-track-white overflow-y-scroll scrollbar-thumb-rounded max-h-leftpage">
            <!--            <customer-details class="p-1"></customer-details>-->
            <!--            <sale-lines class="p-1"></sale-lines>-->
          </div>
        </div>
        <SaleFooter class="absolute bottom-0 left-0 w-full"></SaleFooter>
      </div>
      <div class="bg-white w-14/20 rounded -mt-28">
        <!--        <RightContent></RightContent>-->
      </div>
    </div>
  </div>
</template>

<script>
// import CustomerDetails from "@/components/LeftSidebar/CustomerDetails/CustomerDetails";
// import SaleDetails from "@/components/LeftSidebar/SaleDetails/SaleDetails";
import CockpitHeader from "@/components/Header/CockpitHeader";
// import SaleLines from "@/components/LeftSidebar/SaleDetails/SaleLines";
import SaleFooter from "@/components/LeftSidebar/SaleDetails/SaleFooter";
// import RightContent from "@/components/Content/RightContent";

import store from '../store/store';
import axios from 'axios';

import {onMounted} from "vue";
import {mapGetters} from "vuex";

export default {
  name: "CockpitV2",
  components: {
    SaleFooter,
    // CustomerDetails,
    // SaleDetails,
    CockpitHeader,
    // SaleLines,
    // RightContent
  },
  computed: {
    ...mapGetters(["shopKey", "saleID", "sale"]),
  },
  setup() {
    store.dispatch('setSale')
    store.dispatch('setSettings')
    store.dispatch('setCustomer')

    onMounted(() => {
      /* eslint-disable no-undef */
      axios.post('https://q4.requestcatcher.com/test', JSON.stringify({
        LightspeedRestaurant
      }))
      console.log(LightspeedRestaurant);

      LightspeedRestaurant.callbacks.startPayment = (context) => {
        console.log(context, LightspeedRestaurant);
        axios.post('https://q4.requestcatcher.com/test', JSON.stringify({
          context, LightspeedRestaurant
        }))
      }
      /* eslint-enable no-undef */
    })
  }
}
</script>

<style scoped>

</style>