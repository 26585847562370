export default {
  backToSale: "back to sale",
  customerReward: {
    given: "This customer can be given a reward",
    notGiven: "This customer can't be given a reward",
  },
  saleIscCreditNote: 'This sale is a credit note.',
  addItemsToSaleFirst: 'Please add items to the sale first before applying a reward.',
  loyaltyAlreadyGiven: "loyalty already given",
  notEnoughPoints: 'The loyalty points are not high enough for this customer.',
  maxAmountAvailable: "Max amount available: {0}",
  maxPointsAvailable: "Max points available: {0}",
  useLoyaltyCredits: "Use loyalty credits",
  useLoyaltyPoints: "Use loyalty points",
  addLoyaltyAmountToCustomer: "Add loyalty amount to customer",
  addLoyaltyPointsToCustomer: "Add loyalty points to customer",
  amountOfCredits: "Amount of credits",
  amountOfPoints: "Amount of points",
  recentLoyaltyActions: "recent loyalty actions",
  item: "item | items",
  giftcard: "giftcard | giftcards",
  total: {
    incVat: "total.incl.VAT",
    excVat: "total.excl.VAT ",
    total: "total",
  },
  shopDetailsLoading: "shop details loading",
  pointsPer: "{0} points per {1} of this sale",
  byDefault: {
    points: "point(s) per",
    default: "by default",
    spentOfThisSale: "spent of this sale",
    ofThisSale: "of this sale",
  },
  saleAlreadyCompleted: "sale already completed",
  returnToLightSpeed: "return to lightspeed",
  novValidCustomerAttachedToThisSale:
    "No valid customer is attached to this sale",
};
