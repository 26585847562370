<template>
  <div
    v-if="customer.loading"
    class="bg-grey-400 rounded-md text-gray-500 my-2 w-11/12 mx-auto"
  >
    <div class="flex justify-between rounded-t-md items-center py-1">
      <div class="w-3/4 flex justify-start">
        <div class="loader-black ml-1 max-h-5 my-auto"></div>
        <div class="text-left py-2 ml-2">
          <p class="font-semibold text-xs">
            {{ lang["CustomerLoading"][language] }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="customer.errorMessage == 'No customer attached to sale'"
  ></div>

  <div
    v-else-if="customer.isValidRetail"
    class="bg-green-100 rounded-md text-gray-500 my-2 w-11/12 mx-auto"
  >
    <div class="flex justify-between rounded-t-md items-center py-1">
      <div class="w-3/4 flex justify-start">
        <box-icon
          class="ml-1 max-h-5 my-auto"
          color="#28A745"
          name="user-check"
        ></box-icon>
        <div class="text-left py-2 ml-2">
          <p class="font-semibold text-xs">
            {{ customer.first_name }} {{ customer.last_name }}
          </p>
          <p class="text-gray-500 font-normal text-xs" v-if="customer.email">
            {{ customer.email }}
          </p>
        </div>
      </div>
      <div
        class="w-1/4"
        v-if="
          customer.loyalty_activated &&
          applicationSettings.loyalty_enabled &&
          !applicationSettings.loyalty_using_points_event
        "
      >
        <p class="text-green-700 text-sm font-normal capitalize">
          {{ $t("loyalty") }}
        </p>
        <p class="text-green-700 text-base font-semibold">
          {{ invoiceCurrency }}
          {{ parseFloat(customer.loyalty_amount).toFixed(2) }}
        </p>
      </div>
      <div
        class="w-1/4"
        v-else-if="
          customer.loyalty_activated && applicationSettings.loyalty_enabled
        "
      >
        <p class="text-green-700 text-sm font-normal">Loyalty</p>
        <p class="text-green-700 text-base font-semibold">
          {{ parseFloat(customer.loyalty_points).toFixed(2) }} points
        </p>
      </div>
    </div>
  </div>
  <div
    v-else-if="!customer.isValidRetail"
    class="bg-red-100 rounded-md text-gray-500 my-2 w-11/12 mx-auto"
  >
    <div class="flex justify-between rounded-t-md items-center py-1">
      <div class="flex justify-start">
        <box-icon
          class="ml-1 max-h-5 my-auto"
          color="#DE1A1A"
          name="user-x"
        ></box-icon>
        <div class="text-left py-2 ml-2">
          <p class="font-semibold text-xs">
            {{ customer.first_name }} {{ customer.last_name }}
          </p>
          <p class="text-gray-500 font-normal text-xs" v-if="customer.email">
            {{ customer.email }}
          </p>
        </div>
      </div>
      <div class="w-1/2">
        <p class="text-red-700 text-sm font-normal">
          {{ lang["NonValidRetailCustomer"][language] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lang from "../.././../assets/lang";
export default {
  name: "CustomerDetails",
  computed: {
    ...mapGetters([
      "customer",
      "invoiceCurrency",
      "applicationSettings",
      "language",
    ]),
  },
  setup() {
    function formatDate(date) {
      if (date) {
        console.log(date);
        let splitted = date.split("T")[0].split("-");
        return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
      }
    }

    return {
      formatDate,
      lang,
    };
  },
  data: function() {
    return {
      date: "",
    };
  },
};
</script>

<style scoped>
</style>
