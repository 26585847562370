import {createApp} from 'vue'
import './index.css'
import store from './store/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFinalModal from 'vue-final-modal'
import router from './router'
import App from './App.vue'
import i18n from '@/plugins/i18n';

// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
if(process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

const app = createApp(App)
app.use(VueAxios, axios)
app.use(i18n)
app.use(store)
app.use(router)
app.use(VueFinalModal())
app.mount('#app')
