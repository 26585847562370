<template>
  <div class="text-left">
    <div class="pt-5 ml-8">
      <div class="flex justify-between">
        <p v-if="isCreditNota" class="font-bold text-2xl tracking-widest">
          CREDIT NOTA
        </p>
        <p v-else class="font-bold text-xl tracking-widest uppercase">
          {{ $t("payment") }}
        </p>
        <div>
          <div :class="[change > 0.0 ? '' : 'mb-6']">
            <p
              v-if="balance >= 0"
              :class="[
                'font-semibold',
                hasRightPanelReceipt || hasRightPanelInvoice
                  ? 'text-right text-base pr-2'
                  : 'mr-8 text-xl',
              ]"
            >
              {{ $t("balance") }}:
              <b>{{ invoiceCurrency }} {{ balance.toFixed(2) }}</b>
            </p>
          </div>
          <p
            v-if="change > 0.0"
            :class="[
              'font-semibold',
              hasRightPanelReceipt || hasRightPanelInvoice
                ? 'text-right text-base pr-2'
                : 'mr-8 text-xl',
            ]"
          >
            CHANGE: <b>{{ invoiceCurrency }}{{ change.toFixed(2) }}</b>
          </p>
        </div>
      </div>
      <div
        :class="[
          onlyInvoice ? 'flex flex-row justify-between' : '',
          'scrollbar-thin scrollbar-thumb-blue-600 scrollbar-track-white overflow-y-scroll scrollbar-thumb-rounded max-h-rightpage pb-16',
        ]"
      >
        <div
          :class="[
            onlyInvoice
              ? 'w-1/2 scrollbar-thin scrollbar-thumb-blue-600 scrollbar-track-white overflow-y-scroll scrollbar-thumb-rounded max-h-rightpage pb-12'
              : '',
          ]"
        >
          <div
            v-if="shop.loading"
            class="w-2/3 bg-grey-400 h-20 flex items-center my-4 rounded"
          >
            <div class="loader-black m-3 max-h-5 my-auto w-1/6"></div>
            <div class="ml-2 w-4/6 h-4/6">
              <p class="font-semibold pt-4 text-sm capitalize">
                {{ $t("shopDetailsLoading") }}
              </p>
            </div>
          </div>
          <div
            v-if="Object.entries(shop).length == 0"
            class="
              w-11/12
              mx-auto
              bg-red-100
              h-16
              flex
              items-center
              my-4
              rounded
            "
          >
            <box-icon
              class="max-h-5 w-1/6"
              type="solid"
              name="badge-check"
              color="#AC0000"
            ></box-icon>
            <div class="w-5/6 h-4/6">
              <p class="font-bold text-sm text-red-800 tracking-wider">
                SHOPDETAILS
              </p>
              <p class="text-red-400 font-semibold sm:tracking-wide text-sm">
                Invalid shop
              </p>
            </div>
          </div>
          <form onsubmit="return false;" class="w-11/12">
            <PaymentsWrapper
              :max="sale.totalDue"
              :valid-retail-customer="customer.isValid"
              :customer="customer"
              :less-customer-info="applicationSettings.allow_less_customer_info"
              :payment-methods="paymentMethods"
              :terminal-payments="terminalPayments"
              :credit-account-amount="sale.creditAccountAmount"
              :rounded-payment-ids="
                JSON.stringify(integrationRetailRoundedPaymentIds)
              "
              :enabled-processors="enabledProcessors"
              :terminal-payment-type-ids="terminalIds"
              :open-payment-ids="
                JSON.stringify(integrationRetailOpenPaymentIds)
              "
              :cash-payment-method-id="
                applicationSettings.integration_retail_paid_payment_1_id
              "
              :currency="invoiceCurrency"
              v-if="(paymentMethods ? paymentMethods : []).length"
            >
              <template
                v-for="paymentMethod in paymentMethods"
                :key="paymentMethod.paymentTypeID"
              >
                <div
                  v-if="
                    paymentMethod.toLowerCase() !== 'credit account' &&
                      paymentMethod.toLowerCase() !== 'gift card'
                  "
                  class="w-100 row px-2"
                >
                  <div class="col-lg-7 col-xl-7 p-0">
                    <p
                      class="col-form-label"
                      style="
                        text-overflow: ellipsis;
                        max-height: 25px;
                        white-space: nowrap;
                        overflow: hidden;
                      "
                    >
                      {{ paymentMethod.name }}
                    </p>
                  </div>

                  <input
                    v-if="
                      integrationRetailOpenPaymentIds.includes(
                        paymentMethod.paymentTypeID
                      )
                    "
                    type="number"
                    class="form-control form-control-sm paymentInput mx-2"
                    style="max-width: 20%"
                    id="inlineFormInput"
                    step="0.01"
                    inputmode="decimal"
                    name="{{ paymentMethod.paymentTypeID }}"
                    value="{{ paymentMethod.value }}"
                    :data-rounded-enabled="
                      integrationRetailOpenPaymentIds.includes(
                        paymentMethod.paymentTypeID
                      )
                    "
                  />

                  <span class="form-check text-right">
                    <a
                      v-if="parseFloat(paymentMethod.value) > 0"
                      style="max-width: 10%"
                      class="
                        btn btn-sm btn-info
                        btngeneral
                        text-white
                        mb-1
                        disabled
                      "
                      >Max.</a
                    >

                    <a
                      v-else
                      :id="paymentMethod.paymentTypeID"
                      class="
                        btn btn-sm btn-info
                        btngeneral
                        text-white
                        mb-1
                        maxPayment
                      "
                      >Max.</a
                    >
                  </span>
                </div>

                <div
                  v-else-if="
                    paymentMethod.name.toLowerCase() === 'credit account' &&
                      sale.creditAccountAmount > 0
                  "
                  class="w-100 row px-2"
                >
                  <div class="col-lg-7 col-xl-7 p-0">
                    <p
                      class="col-form-label"
                      style="
                        text-overflow: ellipsis;
                        max-height: 29px;
                        white-space: nowrap;
                        overflow: hidden;
                      "
                    >
                      Account (deposit)
                      <span
                        class="badge badge-default ml-2"
                        style="background-color: #2dce89"
                      >
                        {{ invoiceCurrency
                        }}{{ Math.abs(sale.creditAccountAmount).toFixed(2) }}
                      </span>
                    </p>
                  </div>

                  <input
                    type="number"
                    class="
                      form-control form-control-sm
                      paymentInput
                      creditAccountInput
                      mx-2
                    "
                    style="max-width: 20%"
                    id="inlineFormInput"
                    step="0.01"
                    inputmode="decimal"
                    :max="
                      sale.total < 0
                        ? sale.creditAccountAmount
                        : Math.abs(sale.creditAccountAmount)
                    "
                    :name="paymentMethod.paymentTypeID"
                    :value="paymentMethod.value"
                  />

                  <span class="form-check text-right">
                    <a
                      v-if="parseFloat(paymentMethod.value) > 0"
                      style="max-width: 10%"
                      class="
                        btn btn-sm btn-info
                        btngeneral
                        text-white
                        mb-1
                        disabled
                      "
                      >Max.</a
                    >
                    <a
                      v-else
                      :id="paymentMethod.paymentTypeID"
                      class="
                        btn btn-sm btn-info
                        btngeneral
                        text-white
                        mb-1
                        maxPaymentCredit
                      "
                      >Max.</a
                    >
                  </span>
                </div>
              </template>
            </PaymentsWrapper>
          </form>
        </div>
        <Invoicing
          v-if="customer.isValidRetail && invoicingEnabled"
          :class="[onlyInvoice ? 'w-1/2 pr-8' : 'w-2/3']"
        ></Invoicing>
      </div>
    </div>
  </div>
</template>

<script>
import Invoicing from "@/components/Content/Payment/Invoicing";
import { mapGetters } from "vuex";
import { onMounted, ref } from "vue";
import store from "@/store/store";
import PaymentsWrapper from "@/components/Content/Payment/PaymentProcessor/PaymentsWrapper";
import axios from "axios";

export default {
  name: "Payment",
  components: { PaymentsWrapper, Invoicing },
  computed: {
    ...mapGetters([
      "shop",
      "sale",
      "balance",
      "applicationSettings",
      "customer",
      "paymentMethods",
      "terminalPayments",
      "invoiceCurrency",
      "invoicingEnabled",
      "integrationRetailRoundedPaymentIds",
      "integrationRetailOpenPaymentIds",
      "change",
      "roundedAmount",
      "onlyInvoice",
      "isCreditNota",
      "hasRightPanelInvoice",
      "hasRightPanelReceipt",
    ]),
  },
  setup() {
    let terminalIds = ref([]);
    let enabledProcessors = process.env.VUE_APP_ENABLED_PAYMENT_METHODS.split(
      ","
    );
    axios
      .get("/api/terminalPaymentIds", {
        params: {
          saleId: store.getters.saleID,
          shopKey: store.getters.shopKey,
        },
      })
      .then((response) => {
        terminalIds.value = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    onMounted(() => {
      store.dispatch("setShop");
      store.dispatch("setPaymentMethods");
      store.dispatch("setTerminalPayments");
    });
    return {
      terminalIds,
      enabledProcessors,
    };
  },
};
</script>

<style scoped></style>
