<template>
  <div v-if="sale.completed == 'false'" class="flex justify-end h-full items-center payment-button2 rounded-b">
    <div v-if="!canFinalize || loading" class="flex justify-around items-center px-4 payment-button">
      <div class="loader-black ml-1 max-h-5 my-auto" v-show="loading"></div>

      <button id="convertSaleButton"
        v-if="showFinalizeButtons && !customer.errorMessage && customer.isValidRetail"
        class="h-4/6 m-2 rounded p-3 bg-blue-800 opacity-50 text-white cursor-default text-center text-sm font-medium capitalize"
      >
        {{ $t("invoice") }}
      </button>

      <button id="onlyConvertSaleButton"
        v-if="applicationSettings.payments_enabled && showFinalizeButtons"
        class="text-blue-800 h-4/6 m-2 rounded bg-white p-3 text-center opacity-50 cursor-default text-sm font-medium shadow capitalize"
      >
        {{ $t("receipt") }}
      </button>
    </div>

    <div
      v-else-if="canFinalize"
      class="flex justify-around items-center px-4 payment-button"
    >
      <button id="convertSaleButton" @click="finalizeSaleWithInvoice"
        v-if="showFinalizeButtons && !customer.errorMessage && customer.isValidRetail"
        class="cursor-pointer h-4/6 m-2 rounded p-3 bg-blue-800 text-white text-center text-sm font-medium capitalize"
      >
          {{ $t("invoice") }}
      </button>

      <button id="onlyConvertSaleButton" @click="finalizeSaleWithoutInvoice"
        v-if="showFinalizeButtons && applicationSettings.payments_enabled"
        class="text-blue-800 h-4/6 m-2 rounded p-3 text-center text-sm font-medium bg-white cursor-pointer shadow capitalize"
      >
          {{ $t("receipt") }}
      </button>
    </div>
  </div>

  <div v-else class="bg-blue-100 flex justify-start h-full items-center">
    <p class="pl-4 text-right font-semibold text-sm text-blue-800 uppercase">
      {{ $t("saleAlreadyCompleted") }}
    </p>

    <a :href="returnUrl"
      class="cursor-pointer h-4/6 m-2 rounded p-3 bg-blue-800 text-white text-center text-sm font-medium">
        {{ $t("Return to Lightspeed") }}
    </a>
  </div>
  <div class="receipt-button"></div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/store/store";
import Swal from "sweetalert2";
import {ref} from "vue";
import {round} from "@/helpers/round";

export default {
  name: "RightPanelFooter",
  computed: {
    ...mapGetters([
      "balance",
      "applicationSettings",
      "sale",
      "returnUrl",
      "showFinalizeButtons",
      "customer",
    ]),
    
    canFinalize() {
      let balance = round(store.getters.balance);
      let change = round(store.getters.change);
      let roundedAmount = round(store.getters.roundedAmount);
      if (store.getters.ccvPayment.amount > 0 && !store.getters.canFinalize) {
        return false;
      }
      return (
        balance - roundedAmount == 0.0 ||
        balance + roundedAmount == 0.0 ||
        balance == 0 ||
        change > 0
      );
    },
  },
  setup() {
    let loading = ref(false);
    /* const handleCashChange = (methods) => {
      let handledMethods = JSON.parse(JSON.stringify(methods));
      handledMethods.forEach((method) => {
        if (
          (method.paymentTypeID == store.getters.applicationSettings.integration_retail_rounded_payment_1_id) &&
          method.value !== "0" &&
          method.value !== "" &&
          method.value !== "." &&
          !isNaN(method.value)
        ) {
          //method.value = round(method.value) - round(store.getters.change);
          method.value = round(method.value).toString();
        }
      });

      return handledMethods;
    }; */
    const finalizeSale = (data) => {
        const requestData = {
            user_id: store.getters.shopKey,
            shopKey: store.getters.shopKey,
            extInvoice: "",
            saleData: store.getters.saleData,
            pay_later_in_email: "",
            roundedAmount: store.getters.roundedAmount,
            note: store.getters.note,
            payments: store.getters.paymentMethods,
            sendMail: store.getters.sendMail,
            email: store.getters.email,
            email2: store.getters.email2,
            ...data,
        };

        if(store.getters.urlParams['test-switch']) {
            requestData['test-switch'] = true;
        }

        return axios.post('/api/finalizeSale', requestData).then((response) => {
            if(response.data.switchNeeded) {
                return Swal.fire({
                    title: response.data.message,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: response.data.labels.yes,
                    cancelButtonText: response.data.labels.no,
                    reverseButtons: true
                }).then((result) => {
                    return finalizeSale({
                        switch: !!result.value,
                        ...data,
                    });
                });
            }

            return response;
        })
    };

    const finalizeSaleWithInvoice = function () {
      loading.value = true;
      finalizeSale({})
        .then(function (response) {
          Swal.fire({
            title: response.data.returnData.labelText,
            text: response.data.returnData.labelPrint,
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "Go to Lightspeed",
            confirmButtonColor: "#2c3b4f",
            confirmButtonText: response.data.returnData.printButton,
          }).then((result) => {
            if (result.value) {
              return window.location.replace(
                response.data.returnData.urlPrintInvoice
              );
            }
            return window.location.replace(
              response.data.returnData.urlLightspeed
            );
          });
        })
        .catch(function(error) {
          loading.value = false;
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    };

    const finalizeSaleWithoutInvoice = function () {
      loading.value = true;
      finalizeSale({
          only_sale: true,
      })
        .then(function (response) {
          window.location.href = response.data.returnData.urlLightspeed
            ? response.data.returnData.urlLightspeed
            : "https://us.merchantos.com/register.php";
        })
        .catch(function(error) {
          loading.value = false;
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer:
              '<a target="_blank" href="https://support.mdware.org/nl/">Please contact the support center</a>',
          });
        });
    };

    return {
      finalizeSaleWithInvoice,
      finalizeSaleWithoutInvoice,
      loading,
    };
  },
};
</script>

<style scoped>
</style>
