<template>
  <div :class="[onlyReceipt ? 'w-17/20 mx-auto' : '']">
    <Giftcard
      v-for="validCard in giftCardData"
      :key="validCard.code"
      :validCard="validCard"
    ></Giftcard>
    <Giftcard></Giftcard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Giftcard from "@/components/Content/Payment/Giftcard";

export default {
  name: "GiftcardsWrapper",
  components: { Giftcard },
  computed: {
    ...mapGetters(["giftCardData", "onlyReceipt", "invoiceCurrency"]),
  },
};
</script>

<style scoped>
</style>