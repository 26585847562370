<template>
  <div
    class="
      flex
      justify-around
      items-center
      bg-blue-100
      rounded-md
      px-4
      py-2
      cursor-pointer
      max-h-9
      my-auto
    "
    @click="showModal = true"
  >
    <p class="text-blue-600 text-center text-sm font-semibold capitalize">
      {{ $tc("detail", 2) }}
    </p>
    
    <box-icon
      class="ml-1 max-h-5"
      color="#17A2B8"
      type="solid"
      name="chevron-right"
    ></box-icon>
  </div>

  <vue-final-modal v-model="showModal">
    <div
      class="
        bg-white
        mt-10
        p-8
        w-5/6
        md:max-w-modal
        rounded-lg
        mx-auto
        relative
      "
    >
      <button class="absolute top-0 right-0 mt-2 mr-2" @click="close">
        <box-icon name="x-circle"></box-icon>
      </button>
      <div class="my-3">
        <div class="table-fixed text-gray-500 w-11/12 mx-auto">
          <div class="text-left flex justify-between w-full px-2">
            <p class="w-1/12 text-xs">#</p>
            <p class="w-2/6 text-xs">PRODUCT</p>
            <p class="w-1/6 text-xs">EXCL. TAX</p>
            <p class="w-1/6 text-xs">TOTAL TAX</p>
            <p class="w-3/12 text-xs">{{ lang["TOTAL"][language] }}</p>
          </div>
          <div
            class="text-left border-b-2 py-3 flex w-full px-2 justify-between"
            v-for="saleLine in saleLines"
            :key="saleLine.saleLineID"
            v-bind:class="{
              discount: isDiscount(parseFloat(saleLine.calcTotal)),
            }"
          >
            <p class="w-1/12">{{ saleLine.unitQuantity }}</p>
            <p class="w-2/6" v-if="saleLine.Item && saleLine.Item.description">
              {{ saleLine.Item.description }}
            </p>
            <p v-else class="w-2/3">{{ saleLine.Note.note }}</p>
            <p class="w-1/6">
              {{ applicationSettings.invoice_currency
              }}{{ parseFloat(saleLine.calcSubtotal).toFixed(2) }}
            </p>
            <p class="w-1/6">
              {{ applicationSettings.invoice_currency
              }}{{ parseFloat(saleLine.calcTax1).toFixed(2) }}
            </p>
            <p v-if="saleLine.calcTotal != '0'" class="w-3/12">
              {{ applicationSettings.invoice_currency
              }}{{ parseFloat(saleLine.calcTotal).toFixed(2) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import lang from "../../../assets/lang";

export default {
  name: "SaleDetailsModal",
  computed: {
    ...mapGetters(["saleLines", "applicationSettings", "language"]),
  },
  setup() {
    let showModal = ref(false);
    const close = () => {
      // some code...
      showModal.value = false;
    };

    let isDiscount = (value) => {
      return value < 0;
    };
    return {
      showModal,
      close,
      confirm,
      isDiscount,
      lang,
    };
  },
};
</script>

<style scoped>
.discount {
  background-color: #eef8f0;
}
</style>
