<template>
    <div class="my-3 flex flex-col" v-if="applicationSettings.loyalty_enabled">
        <div
            v-if="loyaltyAdded"
            class="bg-grey-400 flex justify-start items-center p-2 rounded"
        >
            <div class="mx-auto">
                <box-icon name="comment-error" type="solid" color="#6E6E6E"></box-icon>
                
                <div class="text-left pl-4">
                    <p class="font-bold text-sm text-gray-700 text-center">
                        {{ $t('loyaltyAlreadyGiven') }}
                    </p>
                </div>
            </div>
        </div>
        
        <div
            v-else-if="canBeGivenPoints || canBeGivenReward"
            class="bg-green-100 flex justify-start items-center p-2 rounded"
        >
            <box-icon type="solid" name="gift" color="#218D3A"/>
            
            <div class="text-center pl-4">
                <p class="text-green-500 font-bold">
                    {{ $t('customerReward.given') }}
                </p>
            </div>
        </div>
        
        <div
            v-else
            class="bg-gray-200 flex justify-start items-center p-2 mb-4 rounded"
        >
            <div>
                <box-icon name="user-x"></box-icon>
                
                <div class="text-center pl-4">
                    <p class="font-extrabold text-base">
                        {{ $t('customerReward.notGiven') }}
                    </p>
                </div>
                
                <div v-if="sale.total < 0" class="text-left pl-4">
                    <p class="font-bold text-sm text-green-700">
                        {{ $t('saleIsCreditNote') }}
                    </p>
                </div>
                
                <div
                    v-if="applicationSettings.loyalty_using_points_event"
                    class="text-left pl-4"
                >
                    <box-icon name="user-x"></box-icon>
                    
                    <p
                        class="text-sm"
                        v-if="customer.loyalty_points > 0 && saleLines.length === 0"
                    >
                        {{ $t('addItemsToSaleFirst') }}
                    </p>
                    
                    <p
                        class="text-sm"
                        v-if="
                            customer.isValidLoyalty &&
                            applicationSettings.loyalty_points_redeem_threshold >
                            customer.loyalty_points"
                    >
                        {{ $t('notEnoughPoints') }}
                    </p>
                    
                    <p class="text-sm" v-if="!customer.isValidRetail">
                        {{ $t('noValidCustomerAttachedToThisSale') }}
                    </p>
                </div>
                
                <div v-else class="text-left pl-4">
                    <p
                        class="text-sm"
                        v-if="customer.loyalty_amount > 0 && saleLines.length === 0"
                    >
                        {{ $t('addItemsToSaleFirst') }}
                    </p>
                    
                    <p
                        class="text-sm"
                        v-if="
                            customer.isValidLoyalty &&
                            applicationSettings.loyalty_amount_redeem_threshold >
                            customer.loyalty_amount"
                    >
                        {{ $t('notEnoughPoints') }}
                    </p>
                    
                    <p class="text-sm" v-if="!customer.isValidRetail">
                        {{ $t('novValidCustomerAttachedToThisSale') }}
                    </p>
                </div>
            </div>
        </div>
        
        <div v-if="canBeGivenReward && !loyaltyAdded">
            <!-- input and label-->
            <div
                class="flex justify-between border-2 rounded items-center mt-5 bg-white"
            >
                <p class="w-2/3 text-xs lg:text-sm text-left pl-1">
                    {{ $t('useLoyaltyCredits') }} ({{ invoiceCurrency }})
                </p>
                
                <input
                    v-model="loyaltyAmount"
                    class="w-4/6 text-right pr-3 text-gray-400"
                    type="number"
                    :max="customer.loyalty_amount"
                    :placeholder="$t('amountOfCredits')"
                    :disable="isAddingLoyaltyAmount"
                />
                
                <!-- when spinner is not loading and amount > 0-->
                <div
                    v-if="loyaltyAmount > 0 && !isUsingLoyaltyAmount"
                    class="bg-blue-100 p-2 w-1/8 cursor-pointer flex items-center"
                    @click="useLoyaltyAmount"
                >
                    <box-icon type="solid" color="#17A2B8" name="plus-circle"></box-icon>
                </div>
                
                <!-- spinner -->
                <div
                    class="loader m-3 max-h-5 my-auto w-1/6"
                    v-if="isUsingLoyaltyAmount"
                ></div>
            </div>
            
            <!--Max usage message -->
            <div>
                <p class="text-xs text-left pl-2">
                    *{{ $t('maxAmountAvailable', [customer.loyalty_amount]) }}
                </p>
            </div>
        </div>
        
        <!--Points Usage -->
        <div v-if="canBeGivenPoints && !loyaltyAdded">
            <div
                class="flex justify-between border-2 rounded items-center mt-5 bg-white"
            >
                <p class="w-2/3 text-xs lg:text-sm text-left pl-1">
                    {{ $t('useLoyaltyPoints') }}
                </p>
                
                <input
                    v-model="loyaltyPoints"
                    class="w-4/6 text-right pr-3 text-gray-400"
                    type="number"
                    :max="customer.loyalty_points"
                    :disable="isAddingLoyaltyPoints"
                    :placeholder="$t('amountOfPoints')"
                />
                
                <!-- when spinner is not loading and amount > 0 (icon)-->
                <div
                    v-if="loyaltyPoints > 0 && !isUsingLoyaltyPoints"
                    class="bg-blue-100 p-2 w-1/8 cursor-pointer flex items-center"
                    @click="useLoyaltyPoints"
                >
                    <box-icon type="solid" color="#17A2B8" name="plus-circle"></box-icon>
                </div>
                
                <!-- spinner -->
                <div
                    class="loader m-3 max-h-5 my-auto w-1/6"
                    v-if="isUsingLoyaltyPoints"
                ></div>
            </div>
            
            <!--Max usage message -->
            <div>
                <p class="text-xs text-left pl-2">
                    *{{ $t('maxPointsAvailable', [customer.loyalty_points]) }} :
                </p>
            </div>
        </div>
        
        <div
            v-if="
        customer.isValidRetail && applicationSettings.loyalty_using_points_event
      "
            class="my-5"
        >
            <div class="flex justify-between border-2 rounded items-center bg-white">
                <p class="w-2/3 text-xs lg:text-sm text-left pl-1">
                    {{ $t('addLoyaltyPointsToCustomer') }}*
                </p>
                
                <input
                    v-model="loyaltyPointsToAdd"
                    class="w-4/6 text-right pr-3 text-gray-400"
                    type="number"
                    :disable="isAddingLoyaltyPoints"
                    :placeholder="$t('amountOfCredits')"
                />
                
                <!-- when spinner is not loading and amount > 0 (+ icon)-->
                <div
                    v-if="loyaltyPointsToAdd > 0 && !isAddingLoyaltyPoints"
                    class="bg-blue-100 p-2 w-1/8 cursor-pointer flex items-center"
                    @click="addLoyaltyPoints"
                >
                    <box-icon type="solid" color="#17A2B8" name="plus-circle"></box-icon>
                </div>
                
                <!-- spinner -->
                <div
                    class="loader m-3 max-h-5 my-auto w-1/6"
                    v-if="isAddingLoyaltyPoints"
                ></div>
            </div>
            
            <p class="text-xs text-left pl-2">
                *{{
                    $t('pointsPer', [applicationSettings.loyalty_points_conversion_value, applicationSettings.invoice_currency])
                }}
            </p>
        </div>
        <div
            v-if="
        customer.isValidRetail &&
          !applicationSettings.loyalty_using_points_event
      "
            class="my-3"
        >
            <div class="flex justify-between border-2 rounded items-center bg-white">
                <p class="w-2/3 text-xs lg:text-sm text-left pl-1">
                    {{ $t('addLoyaltyAmountToCustomer') }}*
                </p>
                
                <input
                    v-model="loyaltyAmountToAdd"
                    class="w-4/6 text-right pr-3 text-gray-400"
                    type="number"
                    :disable="isAddingLoyaltyAmount"
                    placeholder="Amount of points"
                />
                
                <div
                    v-if="loyaltyAmountToAdd > 0 && !isAddingLoyaltyAmount"
                    class="bg-blue-100 p-2 w-1/8 cursor-pointer flex items-center"
                    @click="addLoyaltyAmount"
                >
                    <box-icon type="solid" color="#17A2B8" name="plus-circle"></box-icon>
                </div>
                
                <!-- spinner -->
                <div
                    class="loader m-3 max-h-5 my-auto w-1/6"
                    v-if="isAddingLoyaltyAmount"
                ></div>
            </div>
            <p class="text-xs text-left pl-2">
                *By default {{ applicationSettings.loyalty_points_conversion_value }}%
                of this sale
            </p>
        </div>
        
        <LoyaltyEventsModal
            v-if="showRecentLoyaltyActionsButton"
        />
    </div>
    <div v-else class="my-3">
        <div class="items-center p-2 rounded">
            <div class="flex align-middle items-center">
                <div>
                    <box-icon name="gift" type="solid" color="#2C3B4F"></box-icon>
                </div>
                
                <p class="font-light text-left text-sm text-green-700 my-2 pl-4">
                    Want to start using loyalty? It takes about 3 minutes and you're good
                    to go!
                </p>
            </div>
            <a href="https://app.mdware.org/enable-loyalty">
                <p
                    class="text-sm font-semibold text-white bg-blue-950 p-2 text-center rounded my-4 w-full"
                >
                    Start your 30 day free Loyalty-trial for Retail.
                </p>
            </a>
        </div>
    </div>
</template>

<script>
import store from "@/store/store";
import LoyaltyEventsModal from "@/components/Content/RightPanel/Loyalty/LoyaltyEventsModal";
import {ref} from "vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "Loyalty",
    computed: {
        ...mapGetters([
            "customer",
            "sale",
            "applicationSettings",
            "saleLines",
            "invoiceCurrency",
            "loyaltyEvents",
        ]),
        canBeGivenReward() {
            let sale = store.getters.sale;
            let applicationSettings = store.getters.applicationSettings;
            let customer = store.getters.customer;
            
            let completed = sale.completed === "true";
            let redeemAmount =
                customer.loyalty_amount > sale.total
                    ? sale.total
                    : customer.loyalty_amount;
            
            return (
                !completed &&
                redeemAmount > 0 &&
                !applicationSettings.loyalty_using_points_event &&
                customer.isValidLoyalty &&
                applicationSettings.loyalty_amount_redeem_threshold <=
                customer.loyalty_amount
            );
        },
        canBeGivenPoints() {
            let sale = store.getters.sale;
            let applicationSettings = store.getters.applicationSettings;
            let customer = store.getters.customer;
            
            let completed = sale.completed === "true";
            let redeemPoints =
                customer.loyalty_points *
                applicationSettings.loyalty_points_redeem_value >
                sale.total
                    ? Math.round(
                        sale.total / applicationSettings.loyalty_points_redeem_value
                    )
                    : customer.loyalty_points;
            
            return (
                !completed &&
                redeemPoints > 0 &&
                applicationSettings.loyalty_using_points_event &&
                customer.isValidLoyalty &&
                applicationSettings.loyalty_points_redeem_threshold <=
                customer.loyalty_points
            );
        },
        showRecentLoyaltyActionsButton() {
            return (
                this.customer &&
                this.customer.isValidLoyalty &&
                this.loyaltyEvents.length > 0
            );
        },
    },
    components: {LoyaltyEventsModal},
    setup() {
        let loyaltyAdded = ref(true);
        let loyaltyAmount = ref("");
        let loyaltyPoints = ref("");
        let loyaltyPointsToAdd = ref("");
        let loyaltyAmountToAdd = ref("");
        let isAddingLoyaltyPoints = ref(false);
        let isAddingLoyaltyAmount = ref(false);
        let isUsingLoyaltyPoints = ref(false);
        let isUsingLoyaltyAmount = ref(false);
        
        const setAmount = function () {
            if (isNaN(parseFloat(loyaltyAmount.value))) {
                console.log(loyaltyAmount.value.charAt(loyaltyAmount.value.length - 1));
            } else {
                loyaltyAmount.value = parseFloat(loyaltyAmount.value)
                    ? parseFloat(loyaltyAmount.value)
                    : 0;
            }
        };
        
        if (!store.getters.isResto) {
            axios
                .get("/api/hasLoyalty", {
                    params: {
                        saleId: store.getters.saleID,
                        shopKey: store.getters.shopKey,
                        accountId: store.getters.accountID,
                    },
                })
                .then(function (response) {
                    // handle success
                    loyaltyAdded.value = response.data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
            
            store.dispatch("setLoyaltyEvents");
        }
        
        const useLoyaltyAmount = function () {
            //to show spinner
            isUsingLoyaltyAmount.value = true;
            axios
                .post("/api/useLoyalty", {
                    saleData: store.getters.saleData,
                    shopKey: store.getters.shopKey,
                    loyaltyAmount: Math.abs(loyaltyAmount.value),
                })
                .then(function (response) {
                    console.log(response);
                    store.dispatch("setSaleLines");
                    store.dispatch("setSale");
                    loyaltyAdded.value = true;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Loyalty credits used!",
                    });
                })
                .catch(function (error) {
                    console.log(error.response.data);
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong!",
                        text: error.response.data.message,
                    });
                })
                .finally(() => {
                    isUsingLoyaltyAmount.value = false;
                });
        };
        const addLoyaltyAmount = function () {
            //to show spinner
            isAddingLoyaltyAmount.value = true;
            
            axios
                .post("/api/addLoyalty", {
                    saleData: store.getters.saleData,
                    shopKey: store.getters.shopKey,
                    loyaltyAmount: Math.abs(loyaltyAmountToAdd.value),
                })
                .then(function (response) {
                    console.log(response);
                    store.dispatch("setCustomer");
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Loyalty added to customer!",
                    });
                })
                .catch(function (error) {
                    console.log(error.response.data);
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong!",
                        text: error.response.data.message,
                    });
                })
                .finally(() => {
                    isAddingLoyaltyAmount.value = false;
                });
        };
        
        const useLoyaltyPoints = function () {
            //for spinner
            isUsingLoyaltyPoints.value = true;
            axios
                .post("/api/useLoyalty", {
                    saleData: store.getters.saleData,
                    shopKey: store.getters.shopKey,
                    loyaltyPoints: Math.abs(loyaltyPoints.value),
                })
                .then(function (response) {
                    console.log(response);
                    store.dispatch("setSaleLines");
                    store.dispatch("setSale");
                    loyaltyAdded.value = true;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Loyalty points used!",
                    });
                })
                .catch(function (error) {
                    console.log(error.response.data);
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong!",
                        text: error.response.data.message,
                    });
                })
                .finally(() => {
                    isUsingLoyaltyPoints.value = false;
                });
        };
        const addLoyaltyPoints = function () {
            //to show spinner
            isAddingLoyaltyPoints.value = true;
            axios
                .post("/api/addLoyalty", {
                    saleData: store.getters.saleData,
                    shopKey: store.getters.shopKey,
                    loyaltyPoints: Math.abs(loyaltyPointsToAdd.value),
                })
                .then(function (response) {
                    console.log(response);
                    store.dispatch("setCustomer");
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Loyalty points added to customer!",
                    });
                })
                .catch(function (error) {
                    console.log(error.response.data);
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong!",
                        text: error.response.data.message,
                    });
                })
                .finally(() => {
                    isAddingLoyaltyPoints.value = false;
                });
        };
        
        return {
            loyaltyAdded,
            loyaltyAmount,
            loyaltyPoints,
            loyaltyAmountToAdd,
            loyaltyPointsToAdd,
            isAddingLoyaltyPoints,
            isAddingLoyaltyAmount,
            isUsingLoyaltyPoints,
            isUsingLoyaltyAmount,
            useLoyaltyAmount,
            useLoyaltyPoints,
            addLoyaltyAmount,
            addLoyaltyPoints,
            setAmount,
        };
    },
    methods: {
        calculateDefaultValuesOfLoyalty() {
            /*Loyalty To be Earned*/
            this.loyaltyPointsToAdd = Math.round(
                this.applicationSettings.loyalty_points_conversion_value *
                this.sale.total
            );
            
            this.loyaltyAmountToAdd =
                (this.applicationSettings.loyalty_amount_conversion_percentage *
                    this.sale.total) /
                100;
        },
    },
    watch: {
        loyaltyAmount(newValue) {
            if (newValue > this.customer.loyalty_amount) {
                this.loyaltyAmount = this.customer.loyalty_amount;
            }
        },
        loyaltyPoints(newValue) {
            if (newValue > this.customer.loyalty_points) {
                this.loyaltyPoints = this.customer.loyalty_points;
            }
        },
    },
    mounted() {
        this.calculateDefaultValuesOfLoyalty();
    },
};
</script>

<style scoped>
.loader {
    border: 2px solid rgb(236, 248, 250);
    border-radius: 50%;
    border-top: 2px solid rgb(23, 162, 184);
    width: 30px;
    height: 19px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

input {
    outline: none;
}
</style>
